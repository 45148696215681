import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NotebookIcon, EllipsisIcon } from "../../../components/Svg";
import { ModalTypes, Urls } from '../../../constants';
import UIState from '../../../store/UIState';
import Ai, { selectAllNotebooks, selectAllVMs, selectNotebookTemplate } from '../../../store/models/Ai';
import { checkDeploymentsReady, getVmResourcesString, getLinkByDeployment } from '../../../utils';
import { getStatusSum, checkActivePod, age } from '../../../utils';
import classNames from 'classnames';
import { Button } from '../../../components/Button';
import { getQueryParams } from '../../../utils/url';
import Loader from '../../../components/Loader';
import { selectCurrentUserId } from '../../../store/models/User';
import { selectCurrentProjectId } from '../../../store/models/Project';
import { selectAppStarted } from '../../../store/AppState';
import { useLocation, useParams } from "react-router";
import _ from "lodash";
import { replaceTo } from "../../../store/history";
import { FaCheckCircle, FaEdit, FaTimesCircle } from "react-icons/fa";
import ApiKeyNote from '../../../components/ApiKeyNote';
import Tooltip from "../../../components/Tooltip";

function JupyterNotebookPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const location = useLocation();
  const { openCreateModal } = getQueryParams(location.search)
  const userId = useSelector(state => selectCurrentUserId(state));
  const projectId = useSelector(state => selectCurrentProjectId(state));
  const notebooks = useSelector(state => selectAllNotebooks(state, projectId));
  const appStarted = useSelector(state => selectAppStarted(state));
  const notebookTemplate = useSelector(state => selectNotebookTemplate(state))
  const hasNoNotebooks = (!_.isNil(notebooks) && _.isEmpty(notebooks));
  const vms = useSelector(state => selectAllVMs(state));
  const [activePopup, setActivePopup] = useState(-1);
  let timeoutId;

  async function fetchData() {
    try {
      setError();
      const notebooks = await dispatch(Ai.actions.fetchJupyterNotebooks(projectId));
      setLoading(false);
      if (!checkDeploymentsReady(notebooks)) {
        timeoutId = setTimeout(fetchData, 1000 * 5)
      }
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!appStarted || _.isNil(userId)) return;
    setLoading(true);
    fetchData();
    return () => { clearTimeout(timeoutId) };
  }, [projectId, Object.keys(notebooks).length]);

  useEffect(() => {
    if (openCreateModal && !_.isEmpty(vms) && notebookTemplate) {
      replaceTo(Urls.AI_JUPYTER_NOTEBOOK, { projectId: projectId ?? 'service' })
      dispatch(UIState.actions.showModal(ModalTypes.CREATE_NOTEBOOK))
    }
  }, [openCreateModal, vms, notebookTemplate]);

  const handleOpenModel = () => {
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_NOTEBOOK))
  }

  const handlePopup = (i) => {
    if (i === -1) {
      setActivePopup(i)
      return;
    }
    setActivePopup(v => {
      if (v === i) return -1;
      return i;
    })
  }

  const handleDelete = (notebook) => {
    const onConfirm = async () => {
      await dispatch(Ai.actions.deleteDeployment(projectId, notebook.Shard, notebook.Suffix));
      await dispatch(Ai.actions.fetchJupyterNotebooks(projectId));
    }
    dispatch(UIState.actions.showModal(ModalTypes.DELETE, { title: notebook.Name, onConfirm }));
    setActivePopup(-1);
  }

  return (<div className={'AiServicePage'}>
    <div className={'AiServicePage__header'}>
      <div className={'AiServicePage__header--title'}>
        Jupyter Notebook
        <ApiKeyNote type={'notebooks'} />
      </div>
      <div className={'AiServicePage__header--button'} onClick={handleOpenModel}>
        <div className={'AiServiceHeaderButtonIcon'}>
          <NotebookIcon />
        </div>
        New Notebook
      </div>
    </div>
    <div className={'AiServicePage__content'}>
      {loading &&
        <div className={'EmptyState'}>
          <Loader size={'large'} color={'green'} />
        </div>}
      {!hasNoNotebooks && !loading && !error && <div className={'AiServicePage__table'}>
        <div className={'AiServicePage__table--header'}>
          <div className={'AiServicePage__table--header-row'}>
            <div className={'AiServicePage__table--cell title'}>Name</div>
            <div className={'AiServicePage__table--cell title'}>ID</div>
            <div className={'AiServicePage__table--cell view'}></div>
            <div className={'AiServicePage__table--cell status'}>Status</div>
            <div className={'AiServicePage__table--cell duration'}>Duration</div>
            <div className={'AiServicePage__table--cell status'}>Machine Type</div>
            <div className={'AiServicePage__table--cell ellipsis'}></div>
          </div>
        </div>
        <div className={'AiServicePage__table--body'}>
          {Object.keys(notebooks).map((k, i) => {
            const notebook = notebooks[k];
            return (
              <div className={'AiServicePage__table--row'} key={i}>
                <div className={'AiServicePage__table--cell title'}>
                  <NameSection deployment={notebook} />
                </div>
                <div className={'AiServicePage__table--cell title'}>
                  <NotebookIcon />
                  {notebook.Name}
                </div>
                <div className={classNames('AiServicePage__table--cell view')}>
                  <div className={classNames('AiServicePageViewButton', { active: checkActivePod(notebook) })}>
                    <a href={checkActivePod(notebook) ? getLinkByDeployment(notebook) : ''} target='_blank' >
                      Open
                    </a>
                  </div>
                </div>
                <div className={'AiServicePage__table--cell status icon'}>{getStatusSum(notebook.PodPhase, false)}</div>
                <div className={'AiServicePage__table--cell duration'}>{age(notebook.CreatedAt)}</div>
                <div className={'AiServicePage__table--cell status'}>
                  <Tooltip tooltip={<div className={"AiServicePage__tooltip"}>
                    {getVmResourcesString(vms[notebook.MachineType].resources)}
                  </div>}>{vms[notebook.MachineType].name}</Tooltip>
                </div>
                <div className={'AiServicePage__table--cell ellipsis'}>
                  <div className={'AiServicePageEllipsis'} onClick={() => handlePopup(i)}>
                    <EllipsisIcon />
                  </div>
                  {activePopup === i && <div className={'AiServicePageEllipsis__popup'} onClick={e => e.stopPropagation()}>
                    <div className={'AiServicePageEllipsis__popup--row'} onClick={() => handleDelete(notebook)}>Delete</div>
                  </div>}
                </div>
              </div>
            )
          })}
        </div>
      </div>}
      {hasNoNotebooks && !loading && !error &&
        <div className={'EmptyState'}>
          <div className={'EmptyState__title'}>No Jupyter NoteBooks</div>
          <div className={'EmptyState__subtitle'}>Deploy your first Jupyter NoteBook to get started</div>
          <Button size={'large'}
            title={'New Notebook'}
            onClick={handleOpenModel}
          />
        </div>}
      {!loading && error && <div className={"AiServicePage__error"}>
        {error}
        <Button onClick={async () => {
          await fetchData();
          setLoading(false);
        }}
          color={"green-outline"}
          title={"Retry"} />
      </div>}
    </div>
  </div>
  );
}

const NameSection = ({ deployment }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const nameRef = useRef();
  const handleEdit = () => {
    setIsEditing(true);
  }
  const handleSubmit = async () => {
    let nickname = nameRef.current.value;
    let projectId = deployment.ProjectID;
    await dispatch(Ai.actions.updateDeployment(deployment.Shard, deployment.Suffix, {
      project_id: projectId,
      annotations: {
        nickname: nickname,
        tags: _.get(deployment, 'Annotations.tags') || '[]'
      }
    }))
    await dispatch(Ai.actions.fetchJupyterNotebooks(projectId));
    setIsEditing(false);
  }
  const handleCancel = () => {
    setIsEditing(false);
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  return <div onClick={e => e.stopPropagation()} className='AiServicePage__name-warp'>
    {!isEditing ? _.get(deployment, 'Annotations.nickname') || `\u00A0` :
      <input type='text' ref={nameRef} defaultValue={_.get(deployment, 'Annotations.nickname')} onKeyDown={handleKeyDown} />}
    {isEditing ?
      <div className='AiServicePage__icon-warp'>
        <div className='PageTitleBar__right--icon cancel' onClick={handleCancel} title="Cancel"><FaTimesCircle /></div>
        <div className='PageTitleBar__right--icon submit' onClick={handleSubmit} title="Submit"><FaCheckCircle /></div>
      </div> :
      <div className='AiServicePage__icon-warp'>
        <div className='PageTitleBar__right--icon edit' onClick={handleEdit}><FaEdit /></div>
      </div>}
  </div>
}

export default JupyterNotebookPage;