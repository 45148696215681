import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../components/Loader";
import PageTitleBar from "../../../components/PageTitleBar";
import { Button } from "../../../components/Button";
import { selectCurrentUserId } from "../../../store/models/User";
import Organization, {
  selectCurrentOrgId,
  selectFeaturesEnabled
} from "../../../store/models/Organization";
import _ from "lodash";
import Project, { selectOrgProjects } from "../../../store/models/Project";
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { DailyChart, StakedBarChart } from "../../../components/Chart";
import { createSelector } from "reselect";
import { Features } from "../../../constants";

const DEFAULT_OPTION = { label: 'All', value: 'all' };
export const UsagePage = () => {

  const dispatch = useDispatch();

  const userId = useSelector(state => selectCurrentUserId(state));
  const orgId = useSelector(state => selectCurrentOrgId(state));
  const projects = useSelector(state => selectOrgProjects(state, orgId));
  const featuresEnabled = useSelector(state => selectFeaturesEnabled(state));
  const projectOptions = [DEFAULT_OPTION].concat(projects.map(p => ({ label: p.name, value: p.id })))
  const [serviceOptions, setServiceOptions] = useState([DEFAULT_OPTION])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState();
  const currentDate = new Date();
  const iniStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
  const [startDate, setStartDate] = useState(iniStartDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [project, setProject] = useState(DEFAULT_OPTION);
  const [service, setService] = useState(DEFAULT_OPTION);
  const [isChanged, setIsChanged] = useState(false);
  const [dataKeys, setDataKeys] = useState([]);
  const [dataColors, setDataColors] = useState([]);
  const [sum, setSum] = useState(0);
  const dataColorsMap = {
    "VirtualMachines": '#18C99D',
    "BandwidthUsage": '#4271fc',
    "TranscodeVideo": '#e06ecd',
    "IngestStream": '#fcee21',
    "RAGChatbot": '#FFA500',
    "Storage": '#663399',
  }

  useEffect(() => {
    fetchData();
    setProject(DEFAULT_OPTION);
    setService(DEFAULT_OPTION)
  }, [orgId]);

  const fetchData = async (project, service) => {
    setLoading(true);
    setError();
    try {
      let sDate = moment(startDate).format('YYYY-MM-DD')
      let eDate = moment(endDate).format('YYYY-MM-DD')
      await dispatch(Project.actions.fetchUserProjectsInOrg(orgId));
      let filter, filterType;
      if (project) {
        filter = project;
        filterType = 'project'
      }
      let res = await dispatch(Organization.actions.fetchOrgUsage(orgId, sDate, eDate, filter, filterType));
      if (!featuresEnabled[Features.STORAGE]) {
        res = {
          serviceNames: _.filter(_.get(res, 'serviceNames'), item => item !== 'Storage'),
          usageData: _.filter(_.get(res, 'usageData'), item => item.service !== 'Storage')
        }
      }
      
      let dates = getDatesBetween(moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD'));
      const arr = [];
      const map = {}
      // new Map(_.get(res, 'usageData').map(item => [item.date.slice(0, 10), (Number(item.credit) / 100).toFixed(2)]));
      _.get(res, 'usageData').forEach(item => {
        let k = item.date.slice(0, 10);
        if (!map[k]) map[k] = {};
        map[k][item.service] = (Number(item.credit) / 100).toFixed(2);
      })
      let dks = _.get(res, 'serviceNames')
      setServiceOptions([{ label: 'All', value: 'all' }]
        .concat(dks.map(item => ({ label: item, value: item }))));
      if (service) {
        setDataKeys([service]);
        setDataColors([dataColorsMap[service]])
      } else {
        setDataKeys(dks);
        setDataColors(dks.map(dk => dataColorsMap[dk]));
      }
      let s = 0;
      dates.forEach(date => {
        let mapObj = map[date];
        let newObj = { name: date };
        if (service) {
          newObj[service] = mapObj ? (Number(mapObj[service]) || 0) : 0;
          s += newObj[service];
        } else {
          dks.forEach(k => {
            newObj[k] = mapObj ? (Number(mapObj[k]) || 0) : 0;
            s += newObj[k];
          })
        }
        arr.push(newObj)
      });
      setSum(s);
      setData(arr);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
      setIsChanged(false);
    }
  }

  const handleChange = (v, type) => {
    if (type === 'project') {
      setProject(v)
    } else if (type === 'service') {
      setService(v)
    }
    if (!isChanged) setIsChanged(true);
  }

  const handleUpdate = async () => {
    let p = project.value === 'all' ? null : project.value;
    let s = service.value === 'all' ? null : service.value;
    fetchData(p, s);
  }

  const handleDownload = () => {
    function convertToCSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';
      var line = '';
      for (var index in array[0]) {
        if (line != '') line += ','
        line += index;
      }
      str += line + '\r\n';
      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
          if (line != '') line += ','

          line += array[i][index];
        }

        str += line + '\r\n';
      }
      return str;
    }


    var csv = convertToCSV(data.map(item => {
      const { name, ...rest } = item;

      const renamed = Object.keys(rest).reduce((acc, key) => {
        acc[`${key}($)`] = rest[key];
        return acc;
      }, {});

      return {
        date: name,
        ...renamed
      };
    }));

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `${orgId}_usage.csv`);
    a.click();
    window.URL.revokeObjectURL(url);
  }


  if (error)
    return (<div className={"ServicePage__Error"}>
      {error}
    </div>)

  return (<div className={"UsagePage"}>
    <PageTitleBar alignTitleLeft={true} title={"Usage"} />

    <div className="UsagePage__content">
      <div className="UsagePage__chart">
        {loading ? <Loader color='green' size='large' /> :
          data ? <div className="UsagePage__chart--container">
            <StakedBarChart data={data}
              width={650}
              height={400}
              legendName={'Usage'}
              dataKeys={dataKeys}
              dataColors={dataColors} />
            <div className="UsagePage__chart--tooltip">
              Total credit consumed within the selected date range: ${sum.toFixed(2)}
            </div>
          </div> : <div className="UsagePage__chart--note">
            Select a project to get started
          </div>}
      </div>
      <div className="UsagePage__selector-wrap">
        <div className="UsagePage__selector-label">Project</div>
        <Select options={projectOptions}
          className={"UsagePage__selector"}
          styles={selectStyles}
          placeholder={'Select a project'}
          value={project}
          onChange={(v) => handleChange(v, 'project')}
        />
        <div className="UsagePage__selector-label">Service</div>
        <Select options={serviceOptions}
          className={"UsagePage__selector"}
          styles={selectStyles}
          placeholder={'Select a service'}
          value={service}
          onChange={(v) => handleChange(v, 'service')}
        />
        <div className="UsagePage__selector-label top">Start Date</div>
        <DatePicker selected={startDate}
          onChange={(v) => {
            setStartDate(v);
            if (!isChanged) setIsChanged(true)
          }}
          disabled={loading}
          maxDate={endDate} />
        <div className="UsagePage__selector-label">End Date</div>
        <DatePicker selected={endDate}
          onChange={(v) => {
            setEndDate(v)
            if (!isChanged) setIsChanged(true)
          }}
          disabled={loading}
          minDate={startDate}
          maxDate={currentDate} />
        <Button className={"UsagePage__button"}
          color={"green"}
          loading={loading}
          title={isChanged ? "Update Chart" : "Download Data"}
          onClick={isChanged ? handleUpdate : handleDownload} />

      </div>
    </div>
  </div>
  )
}

const selectStyles = {
  container: styles => ({
    ...styles,
    flex: 1,
    maxWidth: '90%',
    height: 40,
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    paddingLeft: 15,
    backgroundColor: '#191D29',
    borderColor: '#3D4463',
    borderRadius: 6,
    boxShadow: isFocused ? '0 0 0 1px #18c99d' : 'none',
    color: '#18c99d',
    ':hover': {
      borderColor: '#18c99d',
      boxShadow: '0 0 0 1px #18c99d',
    },
    '.selected': {
      borderColor: '#18c99d',
      boxShadow: '0 0 0 1px #18c99d',
    }
  }),
  menu: (styles, state) => ({
    ...styles,
    border: '1px solid #3D4463',
    backgroundColor: '#191D29',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#191D29',
      borderColor: '#3D4463',
      color: isDisabled ? '#636B91' : (isFocused ? '#18c99d' : (isSelected ? '#18c99d' : '#636B91')),
      opacity: isDisabled ? 0.5 : 1,
    };
  },
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled ? 'none' : 'flex',
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    color: '#636B91',
    backgroundColor: '#636B91',
    display: 'none'
  }),
  input: styles => ({ ...styles }),
  placeholder: styles => ({ ...styles, color: '#636B91' }),
  singleValue: (styles, { data }) => ({ ...styles, color: '#636B91', fontWeight: '500' }),
};

function getDatesBetween(startDate, endDate) {
  let dates = [];

  let currentDate = moment(startDate, 'YYYY-MM-DD');
  let stopDate = moment(endDate);

  while (currentDate <= stopDate) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.clone().add(1, 'day');
  }

  return dates;
}