import User, { LOGOUT, selectCurrentUserId } from "./models/User";
import Storage from "../utils/storage";
import Dapp from "./models/DApp";
import Organization from "./models/Organization";
import Project from "./models/Project";
import Bill from "./models/Bill";


// ===========================
// ACTIONS
// ===========================
export const APP_START = 'APP_START';
export const SET_USER = 'SET_USER';
export const SET_ORG = 'SET_ORG';
export const SET_PROJECT = 'SET_PROJECT';
export const SAVE_ORG_INVITE = 'SAVE_ORG_INVITE';

// ===========================
// SELECTORS
// ===========================
export const selectProjectInvite = state => state.app.projectInvite;
export const selectAppStarted = state => state.app.appStarted;

// ===========================
// MODEL
// ===========================
const AppState = {
  actions: {
    startApp: () => async (dispatch, getState) => {
      const userId = Storage.getUserId();
      const orgId = Storage.getOrgId()
      if (userId) {
        await dispatch(User.actions.fetchUser(userId));
        if (orgId) {
          dispatch(User.actions.getUserRoleInOrg(userId));
          await dispatch(Organization.actions.fetchOrganization(orgId));
          await dispatch(Bill.actions.fetchBalance(orgId));
          dispatch(Organization.actions.fetchWhitelistedOrgs(orgId));
        }
        // await dispatch(Dapp.actions.fetchDApps(userId));
        // TODO re-enable when accounts are migrated to the new service
        // dispatch(Dapp.actions.fetchVideoAPIAccounts(userId));
        // TODO remove this in favor of fetching the service accounts first...

      }

      return await dispatch({
        type: APP_START,
        userId,
        orgId,
      });
    },

    setAuth: (userId, userToken) => (dispatch, getState) => {
      Storage.setUserId(userId);
      Storage.setUserToken(userToken);

      dispatch({
        type: SET_USER,
        userId,
      });
    },

    selectCurrentOrgAndProject: (currentOrgId, projectId) => async (dispatch, getState) => {
      const userId = selectCurrentUserId(getState());
      if (userId) {
        dispatch(User.actions.getUserRoleInOrg(userId, currentOrgId));
        dispatch(Organization.actions.fetchWhitelistedOrgs(currentOrgId));
      }

      await dispatch(AppState.actions.selectOrg(currentOrgId));
      const projects = await dispatch(Project.actions.fetchUserProjectsInOrg(currentOrgId))
      console.log('projects', projects)
      
      if (!_.isEmpty(projects) && !projectId) {
        const sortedProjects = _.orderBy(projects, ['create_time'], ['asc']);
        const oldestProject = sortedProjects[0];
        const projectId = oldestProject.id;
        await dispatch(AppState.actions.selectProject(projectId));
        return projectId
      } else if (projectId) {
        await dispatch(AppState.actions.selectProject(projectId));
        return projectId
      }
    },

    selectOrg: (orgId) => async (dispatch, getState) => {
      Storage.setOrgId(orgId);

      dispatch({
        type: SET_ORG,
        orgId,
      });
    },

    selectProject: (projectId) => async (dispatch, getState) => {
      dispatch(User.actions.visitProject(projectId));
      Storage.setProjectId(projectId);

      dispatch({
        type: SET_PROJECT,
        projectId,
      });
    },

    saveProjectInvite: (projectId, email, token, userId) => async (dispatch, getState) => {
      dispatch({
        type: SAVE_ORG_INVITE,
        projectId,
        email,
        token,
        userId
      });
    },
    clearProjectInvite: () => async (dispatch, getState) => {
      dispatch({
        type: SAVE_ORG_INVITE,
        projectId: null,
        email: null,
        token: null,
        userId: null,
      });
    }
  },

  spec: {
    userId: Storage.getUserId(),
    orgId: Storage.getOrgId(),
    projectId: Storage.getProjectId(),
    appStarted: false,
    projectInvite: {
      projectId: null,
      email: null,
      token: null,
    },
  },

  reducer
    (state = AppState.spec, action) {
    let { result, url, body, json, type } = action;

    switch (type) {
      case APP_START:
        return {
          ...state,
          appStarted: true,
          userId: action.userId,
          orgId: action.orgId
        };
      case SET_USER:
        return { ...state, userId: action.userId };
      case SET_ORG:
        return { ...state, orgId: action.orgId };
      case SET_PROJECT:
        return { ...state, projectId: action.projectId };
      case LOGOUT:
        return { ...state, userId: null };
      case SAVE_ORG_INVITE:
        return {
          ...state,
          projectInvite: {
            projectId: action.projectId,
            email: action.email,
            token: action.token,
            userId: action.userId,
          }
        };
    }
    return state;
  },
}
export default AppState;
