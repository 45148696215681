import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import PageTitleBar from "../../../components/PageTitleBar";
import { Button } from "../../../components/Button";
import { selectCurrentUser, selectCurrentUserId } from "../../../store/models/User";
import {ModalTypes, UserRoleIds, Urls, UserRoleIds as UserRoles} from "../../../constants";
import UIState from "../../../store/UIState";
import Organization, {
  selectCurrentOrgId,
  selectOrganizations,
  selectOrgQuota
} from "../../../store/models/Organization";
import { Tab, Tabs } from "../../../components/Tabs";
import Tooltip from "../../../components/Tooltip";
import { getHardwareDetail, getVmResourcesString } from "../../../utils";
import { replaceTo } from "../../../store/history";
import _ from "lodash";

const selector = createSelector(
  selectCurrentUserId,
  selectCurrentUser,
  selectCurrentOrgId,
  selectOrganizations,
  (state, orgId) => selectOrgQuota(state, orgId ?? selectCurrentOrgId(state)),
  (currentUserId, currentUser, currentOrgId, orgs, orgQuota) => {
    return {
      currentUserId,
      currentUser,
      currentOrgId,
      orgs,
      orgQuota
    }
  }
)


const getQuotaTooltip = (quota) => {
  return (
    <div>
      {quota.description && <div className={"QuotaTooltip__Desc"}>{quota.description}</div>}
      <div
        className={"QuotaTooltip__Info"}>{getVmResourcesString(quota.resources)}</div>
    </div>
  )
}

export const QuotaPage = () => {

  const dispatch = useDispatch();
  const [selectedOrgId, setSelectedOrgId] = useState(null)

  const {
    currentUserId,
    currentUser,
    currentOrgId,
    orgs,
    orgQuota
  } = useSelector(state => selector(state, selectedOrgId));

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchUserOrgs()
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedOrgId)) {
      fetchOrgQuota()
    }
  }, [selectedOrgId]);



  useEffect(() => {
    if (!_.isEmpty(currentUser)) {
      if (currentUser.role !== UserRoles.ADMIN && currentUser?.role !== UserRoleIds.EDITOR)
        replaceTo(Urls.SETTINGS_PROFILE);
    }
  }, [currentUser])

  useEffect(() => {
    if (!_.isEmpty(currentOrgId)) {
      setSelectedOrgId(currentOrgId)
    }
  }, [currentOrgId]);
  const fetchUserOrgs = async () => {
    setLoading(true);
    setError(null);
    try {
      await dispatch(Organization.actions.fetchUserOrganizations(currentUserId))
    } catch (e) {
      setError(e.message)
    }
    setLoading(false);
  }

  const fetchOrgQuota = async () => {
    setLoading(true);
    setError(null);
    try {
      await dispatch(Organization.actions.fetchOrgQuota(selectedOrgId))
    } catch (e) {
      setError(e.message)
    }
    setLoading(false);
  }

  const onIncreaseQuota = () => {
    dispatch(UIState.actions.showModal(ModalTypes.INCREASE_QUOTA, {orgId: selectedOrgId}))
  }

  if (error)
    return (<div className={"ServicePage__Error"}>
      {error}
      <Button onClick={fetchOrgQuota()}
        color={"green-outline"}
        title={"Retry"} />
    </div>)

  return (<div className={"QuotaPage"}>
    <PageTitleBar alignTitleLeft={true} title={"Organization Quota"} />

    <Tabs leftAlign={true}>
      {_.map(orgs, org => {
        return <Tab key={org.id}
          isSelected={(selectedOrgId === org.id)}
          onClick={() => setSelectedOrgId(org.id)}>
          {org.name}
        </Tab>
      })}
    </Tabs>

    {loading
      ? <Loader />
      : <>
      <Button onClick={onIncreaseQuota}
              color={"green-outline"}
              title={"Increase quota"} />
      <table>
        <thead>
          <tr>
            <th>Machine Type</th>
            <th>Quota</th>
            <th>Amount Used</th>
            <th>Specs</th>
          </tr>
        </thead>
        <tbody>
          {_.map(orgQuota, (quota, key) => {
            return (<tr key={key}>
              <td><Tooltip tooltip={getQuotaTooltip(quota)}>{quota.name}</Tooltip></td>
              <td>{quota.quota ?? 0}</td>
              <td>{quota.usage ?? 0}</td>
              <td>{getVmResourcesString(quota.resources)}</td>
            </tr>)
          })}
        </tbody>
      </table>
    </>}
  </div>
  )
}
