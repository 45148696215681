import React, { useEffect, useState } from 'react';
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Organization, { selectCurrentOrg, selectCurrentOrgId, selectOrganizations } from "../store/models/Organization";
import AppState from "../store/AppState";
import { ModalTypes } from "../constants";
import UIState from "../store/UIState";
import Project, { selectCurrentProject, selectCurrentProjectId, selectOrgProjects, selectUsersForProjectsInOrg } from "../store/models/Project";
import { createSelector } from "reselect";
import dayjs from "dayjs";
import { SelectInput } from "./form/SelectInput";
import { useHistory } from "react-router";
import { updateAiProjectId } from '../utils';
import { OrgAvatar } from "./OrgAvatar";
import { PlusIcon } from './Svg';

const selector = createSelector(
  selectCurrentOrgId,
  selectCurrentOrg,
  selectCurrentProjectId,
  selectCurrentProject,
  (state) => selectOrgProjects(state, selectCurrentOrgId(state)),
  selectOrganizations,
  (state, orgId) => selectUsersForProjectsInOrg(state, orgId),
  (currentOrgId, currentOrg, currentProjectId, currentProject, projects, organizations, users) => (
    { currentOrgId, currentOrg, currentProjectId, currentProject, projects, organizations, users }
  )
)

const OrgLabel = ({ org }) => {
  if (_.isEmpty(org)) return <></>

  return (
    <div className={"OrgLabel"}>
      {/* <OrgAvatar org={org}/> */}
      <div className={"OrgLabel__name"}>{org.name}</div>
    </div>
  )
}

const CreateNewOrgOption = () => {
  return (
    <div className="SelectProjectModal__SelectOption CreateNewOption">
      <PlusIcon className="CreateNewOption__icon" />
      <span>Create new organization</span>
    </div>
  );
};

const SelectOrgOption = ({ org }) => {
  const myOrg = org.user_join_time === org.create_time
  const joined = myOrg ? 'Created' : 'Joined'
  return (
    <div className={"SelectProjectModal__SelectOption"}>
      <div className={"SelectProjectModal__SelectOption__name"}>{org.name}</div>
      <div className={"SelectProjectModal__SelectOption__id"}>Owned by {org.email}</div>
      <div
        className={"SelectProjectModal__SelectOption__id"}>{joined} {dayjs(org.user_join_time).format('MMM D, YYYY')}</div>
    </div>
  )
}

const SelectProjectOption = ({ project }) => {
  return (
    <div className={"SelectProjectModal__SelectOption"}>
      <div className={"SelectProjectModal__SelectOption__name"}>{project.name}</div>
    </div>
  )
}

const CreateNewProjectOption = () => {
  return (
    <div className="SelectProjectModal__SelectOption CreateNewOption">
      <PlusIcon className="CreateNewOption__icon" />
      <span>Create new project</span>
    </div>
  );
};

const CREATE_NEW_ORG = 'CREATE_NEW_ORG';
const CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT';

export const OrgAndProjectDropdown = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { currentOrgId, currentProjectId, currentOrg, currentProject, organizations, projects, users }
    = useSelector(state => selector(state));

  useEffect(() => {
    if (currentOrgId) {
      fetchData()
    }
  }, [currentOrgId]);

  const fetchData = async () => {
    try {
      await dispatch(Project.actions.fetchUserProjectsInOrg(currentOrgId, {
        expand: 'user_ids'
      }))
      if (_.isEmpty(organizations))
        dispatch(Organization.actions.fetchUserOrganizations());
    } catch (e) {
    }
  }

  const onSelectOrg = async (e) => {
    const orgId = e.value;

    if (orgId === CREATE_NEW_ORG) {
      dispatch(UIState.actions.showModal(ModalTypes.CREATE_ORG))
      return
    }

    const projectId = await dispatch(AppState.actions.selectCurrentOrgAndProject(orgId))
    updateAiProjectId(history, projectId)
  }

  const onSelectProject = async (e) => {
    const projectId = e.value;

    if (projectId === CREATE_NEW_PROJECT) {
      dispatch(UIState.actions.showModal(ModalTypes.CREATE_PROJECT))
      return
    }

    dispatch(AppState.actions.selectProject(projectId))
    updateAiProjectId(history, projectId)
  }

  return (<div className={"OrgAndProjectDropdowns"}>
    <SelectInput value={{ label: <OrgLabel org={currentOrg} />, value: currentOrg?.id }}
      options={[
        { label: "Organizations", value: null, isDisabled: true },
        ...organizations.map(org => ({ label: <SelectOrgOption org={org} />, value: org.id })),
        { label: <CreateNewOrgOption />, value: CREATE_NEW_ORG }
      ]}
      styles={selectStyles}
      onChange={onSelectOrg} />

    <div className={"OrgAndProjectDropdowns__Separator"}>/</div>

    <SelectInput value={{ label: currentProject?.name, value: currentProject?.id }}
      options={[
        { label: "Projects", value: null, isDisabled: true },
        ...projects.map(project => ({
          label: <SelectProjectOption project={project} />,
          value: project.id
        })),
        { label: <CreateNewProjectOption />, value: CREATE_NEW_PROJECT }
      ]}
      styles={selectStyles}
      onChange={onSelectProject} />
  </div>
  );
}


const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#131722',
    borderColor: 'transparent',
    borderRadius: '4px',
    color: '#8A8FB5',
    outline: 'none',
    minHeight: 'none',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#18C99D', // Add your hover styles here
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#131722' :
      state.isFocused ? 'rgba(24, 201, 157, 0.1)' : '#131722',
    color: state.isDisabled ? '#FFFFFF' :
      state.isFocused ? '#18C99D' :
        state.isSelected ? '#18C99D' : '#8A8FB5',
    fontFamily: 'Gilroy',
    paddingLeft: '10px',
    fontWeight: '500',
    fontSize: '14px',
    paddingTop: '11.5px',
    paddingBottom: '11.5px',
    height: 'auto',
    position: 'relative',
    cursor: state.isDisabled ? 'default' : 'pointer',
    ':hover': {
      backgroundColor: state.isDisabled ? '#131722' : 'rgba(24, 201, 157, 0.1)',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    borderColor: state.isSelected ? '#18C99D' : 'transparent',
    color: '#8A8FB5',
    fontFamily: 'Gilroy',
    fontWeight: '500',
    fontSize: '14px',
    outline: 'none'
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#8A8FB5',
    marginRight: '0',
    marginLeft: '4px',
    ':hover': {
      color: '#8A8FB5', // Add your hover styles here
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#131722',
    // border: '1px solid #3D4463',
    borderRadius: '4px',
    scrollbarColor: '#3D4463',
    width: '250px',
    zIndex: 3
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '500px',
  }),
}