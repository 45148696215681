import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ServiceSidebarItem } from "../../../components/ServiceSidebarItem";
import { mergeUrl } from "../../../utils/url";
import { Urls, UserRoleIds } from "../../../constants";
import { BillingIcon, ProfileIcon, ProjectsIcon, QuotaIcon, SecurityIcon, SupportIcon, TeamsIcon, UsageIcon } from "../../../components/Svg";
import { TeamMembersPage } from "./TeamMembersPage";
import { ProjectsPage } from "./ProjectsPage";
import { ProjectUsersPage } from "./ProjectUsersPage";
import { ProfilePage } from "./ProfilePage";
import { SecurityPage } from "./SecurityPage";
import { BillingPage } from './BillingPage';
import { QuotaPage } from "./QuotaPage";
import { selectCurrentUserId, selectCurrentUserRole } from "../../../store/models/User";
import { createSelector } from "reselect";
import { toast } from "react-toastify";
import { selectCurrentOrg } from '../../../store/models/Organization';
import { Overlay } from '../../../components/Overlay';
import { SupportPage } from './SupportPage';
import { UsagePage } from './UsagePage';
// const selector = createSelector(
//   selectCurrentUser,
//   selectCurrentUserId,
//   (currentUser, currentUserId) => (
//     { currentUser, currentUserId }
//   )
// );

const SettingsPage = ({ match, ...props }) => {
  const path = match.path;
  // const { currentUser, currentUserId } = useSelector(state => selector(state))
  const role = useSelector(state => selectCurrentUserRole(state));
  const userId = useSelector(state => selectCurrentUserId(state));
  const currentOrg = useSelector(state => selectCurrentOrg(state));
  const currentUserIsEditor = role === UserRoleIds.ADMIN || role === UserRoleIds.EDITOR;
  const currentUserIsAdmin = role === UserRoleIds.ADMIN;

  return (<div className={'ServiceContainer'}>
    {(!userId || (currentOrg && !currentOrg.disabled)) && <>
      <div className='ServiceSidebar'>

        <div className={'ServiceSidebar__title'}>Account</div>
        <ServiceSidebarItem title={'Profile'}
          active={path === Urls.SETTINGS_PROFILE || path === Urls.DASHBOARD_SETTINGS}
          icon={<ProfileIcon />}
          link={mergeUrl(Urls.SETTINGS_PROFILE)} />

        <ServiceSidebarItem title={'Security'}
          active={path === Urls.SETTINGS_SECURITY}
          icon={<SecurityIcon />}
          link={mergeUrl(Urls.SETTINGS_SECURITY)} />

        <ServiceSidebarItem title={'Teams'}
          active={path === Urls.SETTINGS_TEAMS}
          icon={<TeamsIcon />}
          link={mergeUrl(Urls.SETTINGS_TEAMS)} />

        <ServiceSidebarItem title={'Projects'}
          active={path === Urls.SETTINGS_PROJECTS || path === Urls.SETTINGS_PROJECT}
          icon={<ProjectsIcon />}
          link={mergeUrl(Urls.SETTINGS_PROJECTS)} />

        {currentUserIsEditor &&
          <ServiceSidebarItem title={'Quota'}
            active={path === Urls.SETTINGS_QUOTA}
            icon={<QuotaIcon />}
            link={mergeUrl(Urls.SETTINGS_QUOTA)} />}

        {currentUserIsAdmin &&
          <ServiceSidebarItem title={'Billing'}
            active={path === Urls.SETTINGS_BILLING}
            icon={<BillingIcon />}
            link={mergeUrl(Urls.SETTINGS_BILLING)} />}

        {currentUserIsAdmin &&
          <ServiceSidebarItem title={'Usage'}
            active={path === Urls.SETTINGS_USAGE}
            icon={<UsageIcon />}
            link={mergeUrl(Urls.SETTINGS_USAGE)} />}

        <ServiceSidebarItem title={'Support'}
          active={path === Urls.SETTINGS_SUPPORT}
          icon={<SupportIcon />}
          link={mergeUrl(Urls.SETTINGS_SUPPORT)} />

      </div>

      <div className={'ServicePage'}>
        <div className={'ServicePage__content'}>
          {(path === Urls.SETTINGS_TEAMS) && <TeamMembersPage />}
          {(path === Urls.SETTINGS_PROJECTS) && <ProjectsPage />}
          {(path === Urls.SETTINGS_PROJECT) && <ProjectUsersPage />}
          {(path === Urls.SETTINGS_SECURITY) && <SecurityPage />}
          {(path === Urls.SETTINGS_QUOTA) && <QuotaPage />}
          {(path === Urls.SETTINGS_BILLING) && <BillingPage />}
          {(path === Urls.SETTINGS_USAGE) && <UsagePage />}
          {(path === Urls.SETTINGS_SUPPORT) && <SupportPage />}
          {(path === Urls.DASHBOARD_SETTINGS || path === Urls.SETTINGS_PROFILE) && <ProfilePage />}
        </div>
      </div>
    </>}
    {currentOrg && currentOrg.disabled && <Overlay type={'disabled'} />}
  </div>
  );
}

export default SettingsPage;
