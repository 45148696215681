import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import React, {useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import PageTitleBar from "../../../components/PageTitleBar";
import {Button} from "../../../components/Button";
import {selectCurrentUser, selectCurrentUserId} from "../../../store/models/User";
import {ProjectIcon} from "../../../components/Svg";
import {ModalTypes, Urls, UserRoleIds} from "../../../constants";
import UIState from "../../../store/UIState";
import Project, {selectOrgProjects, selectUsersForProjectsInOrg} from "../../../store/models/Project";
import Organization, {selectCurrentOrgId, selectOrganizations} from "../../../store/models/Organization";
import _ from "lodash";
import classNames from "classnames";
import dayjs from "dayjs";
import {UserListRowItem} from "../../../components/UserListRowItem";
import {pushTo} from "../../../store/history";
import {mergeUrl} from "../../../utils/url";
import {Tab, Tabs} from "../../../components/Tabs";

const selector = createSelector(
  selectCurrentUserId,
  selectCurrentUser,
  selectCurrentOrgId,
  selectOrganizations,
  (state, orgId) => selectOrgProjects(state, orgId ?? selectCurrentOrgId(state)),
  (state, orgId) => selectUsersForProjectsInOrg(state, orgId ?? selectCurrentOrgId(state)),
  (currentUserId, currentUser, currentOrgId, organizations, projects, users) => {
    return {
      currentUserId,
      currentUser,
      currentOrgId,
      organizations,
      projects,
      users
    }
  }
)

export const ProjectsPage = () => {

  const dispatch = useDispatch();
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const {currentUserId, currentUser, currentOrgId, organizations, projects, users} = useSelector(state => selector(state, selectedOrgId));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const currentUserIsAdmin = currentUser?.role === UserRoleIds.ADMIN;

  useEffect(() => {
    fetchUserOrgs();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedOrgId)) {
      fetchProjectsInOrg();
    }
  }, [selectedOrgId]);

  useEffect(() => {
    if (!_.isEmpty(currentOrgId)) {
      setSelectedOrgId(currentOrgId);
    }
  }, [currentOrgId]);

  const fetchUserOrgs = async () => {
    setLoading(true);
    setError(null);
    try {
      await dispatch(Organization.actions.fetchUserOrganizations(currentUserId));
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  }

  const fetchProjectsInOrg = async () => {
    setLoading(true);
    setError(null);
    try {
      await dispatch(Project.actions.fetchUserProjectsInOrg(selectedOrgId, {expand: 'user_ids'}));
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  }

  const onNewProject = () => {
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_PROJECT, {orgId: selectedOrgId}));
  }

  const onSelectProject = (project) => {
    pushTo(mergeUrl(Urls.SETTINGS_PROJECT, {projectId: project.id}));
  }

  const rightButton = currentUserIsAdmin
    ? <Button title={'Create Project'}
              color={"green-outline"}
              onClick={onNewProject}/>
    : null;

  if (error)
    return (
      <div className={"ServicePage__Error"}>
        {error}
        <Button onClick={fetchProjectsInOrg()}
                color={"green-outline"}
                title={"Retry"}/>
      </div>
    );

  return (
    <div className={"ProjectsPage"}>
      <PageTitleBar alignTitleLeft={true} title={"Projects"} rightContent={rightButton}/>

      <Tabs leftAlign={true}>
        {_.map(organizations, org => (
          <Tab key={org.id}
               isSelected={(selectedOrgId === org.id)}
               onClick={() => setSelectedOrgId(org.id)}>
            {org.name}
          </Tab>
        ))}
      </Tabs>

      {loading ? (
        <Loader />
      ) : (
        <ul className={'ItemsList'}>
          {projects.map(project => {
            const projectUsers = _.filter(users, user => project?.user_ids?.includes(user.id));
            return (
              <li key={project.id}
                  onClick={() => onSelectProject(project)}
                  className={classNames('ItemsListItem')}>
                <div className={'ItemsListItem__name ItemsListItem__name--l ItemsListItem__33'}>
                  <ProjectIcon className={'ItemsListItem__name__icon'} /> {project.name}
                </div>
                <div className={'ItemsListItem__url ItemsListItem__33'}>
                  Created {dayjs(project.create_time).format('MMM D, YYYY')}
                </div>
                <div className={'ItemsListItem__users ItemsListItem__33'}>
                  <UserListRowItem users={projectUsers} />
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
