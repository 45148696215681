import React from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';

import SvgLivestream from '../assets/icons/icon-livestream.svg';
import SvgVideo from '../assets/icons/icon-video.svg';
import SvgWebhook from '../assets/icons/icon-webhook.svg';
import SvgSettings from '../assets/icons/icon-settings.svg';
import SvgAI from '../assets/icons/icon-ai.svg';
import SvgRendering from '../assets/icons/icon-rendering.svg';
import SvgDocuments from '../assets/icons/icon-documents.svg';
import SvgPrototyping from '../assets/icons/icon-prototyping.svg';
import SvgTraining from '../assets/icons/icon-training.svg';
import SvgServing from '../assets/icons/icon-serving.svg';
import SvgClusters from '../assets/icons/icon-clusters.svg';
import SvgProfile from '../assets/icons/icon-profile.svg';
import SvgSecurity from '../assets/icons/icon-security.svg';
import SvgTeams from '../assets/icons/icon-teams.svg';
import SvgProjects from '../assets/icons/icon-projects.svg';
import SvgQuota from '../assets/icons/icon-quota.svg';
import SvgClose from '../assets/icons/icon-close.svg';
import SvgNotebook from '../assets/icons/icon-notebook.svg';
import SvgDeployment from '../assets/icons/icon-deployment.svg';
import SvgEllipsis from '../assets/icons/icon-ellipsis.svg';
import SvgBackArrow from '../assets/icons/icon-back-arrow.svg';
import ScgClose from '../assets/icons/icon-close.svg';
import SvgDownChevron from '../assets/icons/icon-chevron-down.svg';
import SvgProject from '../assets/icons/icon-project.svg';
import SvgCheck from '../assets/icons/icon-check.svg';
import SvgTheta from '../assets/icons/icon-theta-ai.svg';
import SvgCloud from '../assets/icons/icon-cloud.svg';
import SvgDataCenter from '../assets/icons/icon-data-center.svg';
import SvgDistributedNode from '../assets/icons/icon-distributed-node.svg';
import SvgGPU from '../assets/icons/icon-gpu.svg';
import SvgInvite from '../assets/icons/icon-invite.svg';
import SvgVerticalDots from '../assets/icons/icon-vertical-dots.svg';
import SvgEdit from '../assets/icons/icon-edit.svg';
import SvgBilling from '../assets/icons/icon-billing.svg';
import SvgStripe from '../assets/icons/icon-stripe.svg';
import SvgCheckmark from '../assets/icons/icon-checkmark.svg';
import SvgArrowSquare from '../assets/icons/icon-arrow-square.svg';
import SvgInfo from '../assets/icons/icon-info.svg';
import SvgCopy from '../assets/icons/icon-copy.svg';
import SvgDownBar from '../assets/icons/icon-down-bar.svg';
import SvgUpBar from '../assets/icons/icon-up-bar.svg';
import SvgRefresh from '../assets/icons/icon-refresh.svg';
import SvgSend from '../assets/icons/icon-send.svg';
import SvgEraser from '../assets/icons/icon-eraser.svg';
import SvgWarning from '../assets/icons/icon-warning.svg';
import SvgHorizontalBars from '../assets/icons/icon-horizontal-bars.svg';
import SvgOverview from '../assets/icons/icon-overview.svg';
import SvgWeb3Theatre from '../assets/icons/icon-web3theatre.svg';
import SvgMenu from '../assets/icons/icon-menu.svg';
import SvgSupport from '../assets/icons/icon-support.svg';
import SvgCamera from '../assets/icons/icon-camera.svg';
import SvgDownload from '../assets/icons/icon-download.svg';
import SvgDownloadAlt from '../assets/icons/icon-download-alt.svg';
import SvgModel from '../assets/icons/icon-model.svg';
import SvgGarment from '../assets/icons/icon-garment.svg';
import SvgImage from '../assets/icons/icon-image.svg';
import SvgObject from '../assets/icons/icon-object.svg';
import SvgDraw from '../assets/icons/icon-draw.svg';
import SvgRuler from '../assets/icons/icon-ruler.svg';
import SvgPalette from '../assets/icons/icon-palette.svg';
import SvgBack from '../assets/icons/icon-back.svg';
import SvgQuestionMark from '../assets/icons/icon-question-mark.svg';
import SvgUsage from '../assets/icons/icon-usage.svg';
import SvgPlus from '../assets/icons/icon-plus.svg';
import SvgAgenticAi from '../assets/icons/icon-agentic-ai.svg';
import SvgUploadIcon from '../assets/icons/icon-upload-icon.svg';
import SvgBeta from '../assets/icons/icon-beta.svg';
import SvgRotate from '../assets/icons/icon-rotate.svg';
import SvgDelete from '../assets/icons/icon-delete.svg';
import SvgTFuel from '../assets/icons/icon-tfuel.svg';
import SvgDollar from '../assets/icons/icon-dollar.svg';
// ====================
// SVG
// ====================
const svg = (source, className) => <SVG className={cx('svg', className)} src={source} />;


// ====================
// ROOT COMPONENT
// ====================
export const Icon = React.forwardRef(({baseClassName, className, size, color, svg, ...props}, ref) => (
  <div ref={ref} className={cx("g-icon", className, size, color, baseClassName)}>{ svg }</div>
))
const createIcon = (svg, baseClassName) => React.forwardRef((props,ref) => <Icon baseClassName={baseClassName} svg={svg} ref={ref} {...props} />)


// ====================
// SVG
// ====================

// ====================
// ICON COMPONENTS
// ====================

export const LivestreamIcon = createIcon(svg(SvgLivestream), 'icon-livestream');
export const VideoIcon = createIcon(svg(SvgVideo), 'icon-video');
export const WebhookIcon = createIcon(svg(SvgWebhook), 'icon-webhook');
export const SettingsIcon = createIcon(svg(SvgSettings), 'icon-settings');
export const AiIcon = createIcon(svg(SvgAI), 'icon-ai');
export const RenderingIcon = createIcon(svg(SvgRendering), 'icon-rendering');
export const DocumentsIcon = createIcon(svg(SvgDocuments), 'icon-documents');
export const PrototypingIcon = createIcon(svg(SvgPrototyping), 'icon-prototyping');
export const TrainingIcon = createIcon(svg(SvgTraining), 'icon-training');
export const ServingIcon = createIcon(svg(SvgServing), 'icon-serving');
export const ClustersIcon = createIcon(svg(SvgClusters), 'icon-clusters');
export const ProfileIcon = createIcon(svg(SvgProfile), 'icon-profile');
export const SecurityIcon = createIcon(svg(SvgSecurity), 'icon-security');
export const TeamsIcon = createIcon(svg(SvgTeams), 'icon-teams');
export const ProjectsIcon = createIcon(svg(SvgProjects), 'icon-projects');
export const QuotaIcon = createIcon(svg(SvgQuota), 'icon-quota');
export const NotebookIcon = createIcon(svg(SvgNotebook), 'icon-notebook');
export const DeploymentIcon = createIcon(svg(SvgDeployment), 'icon-deployment');
export const EllipsisIcon = createIcon(svg(SvgEllipsis), 'icon-ellipsis');
export const BackArrowIcon = createIcon(svg(SvgBackArrow), 'icon-back-arrow');
export const CloseIcon = createIcon(svg(ScgClose), 'icon-close');
export const DownChevronIcon = createIcon(svg(SvgDownChevron), 'icon-down-chevron');
export const ProjectIcon = createIcon(svg(SvgProject), 'icon-project');
export const CheckIcon = createIcon(svg(SvgCheck), 'icon-check');
export const ThetaIcon = createIcon(svg(SvgTheta), 'icon-theta-ai');
export const CloudIcon = createIcon(svg(SvgCloud), 'icon-cloud');
export const DataCenterIcon = createIcon(svg(SvgDataCenter), 'icon-data-center');
export const DistributedNodeIcon = createIcon(svg(SvgDistributedNode), 'icon-distributed-node');
export const GPUIcon = createIcon(svg(SvgGPU), 'icon-gpu');
export const InviteIcon = createIcon(svg(SvgInvite), 'icon-invite');
export const VerticalDotsIcon = createIcon(svg(SvgVerticalDots), 'icon-vertical-dots');
export const EditIcon = createIcon(svg(SvgEdit), 'icon-edit');
export const BillingIcon = createIcon(svg(SvgBilling), 'icon-billing');
export const StripeIcon = createIcon(svg(SvgStripe), 'icon-stripe');
export const CheckmarkIcon = createIcon(svg(SvgCheckmark), 'icon-checkmark');
export const ArrowSquareIcon = createIcon(svg(SvgArrowSquare), 'icon-arrow-square');
export const InfoIcon = createIcon(svg(SvgInfo), 'icon-info');
export const CopyIcon = createIcon(svg(SvgCopy), 'icon-copy');
export const DownBarIcon = createIcon(svg(SvgDownBar), 'icon-down-bar');
export const UpBarIcon = createIcon(svg(SvgUpBar), 'icon-up-bar');
export const RefreshIcon = createIcon(svg(SvgRefresh), 'icon-refresh');
export const SendIcon = createIcon(svg(SvgSend), 'icon-send');
export const EraserIcon = createIcon(svg(SvgEraser), 'icon-eraser');
export const WarningIcon = createIcon(svg(SvgWarning), 'icon-warning');
export const HorizontalBarsIcon = createIcon(svg(SvgHorizontalBars), 'icon-horizontal-bars');
export const OverviewIcon = createIcon(svg(SvgOverview), 'icon-overview');
export const Web3TheatreIcon = createIcon(svg(SvgWeb3Theatre), 'icon-web3theatre');
export const MenuIcon = createIcon(svg(SvgMenu), 'icon-menu');
export const SupportIcon = createIcon(svg(SvgSupport), 'icon-support');
export const CameraIcon = createIcon(svg(SvgCamera), 'icon-camera');
export const DownloadIcon = createIcon(svg(SvgDownload), 'icon-download');
export const DownloadAltIcon = createIcon(svg(SvgDownloadAlt), 'icon-download-alt');
export const ModelIcon = createIcon(svg(SvgModel), 'icon-model');
export const GarmentIcon = createIcon(svg(SvgGarment), 'icon-garment');
export const ImageIcon = createIcon(svg(SvgImage), 'icon-image');
export const ObjectIcon = createIcon(svg(SvgObject), 'icon-object');
export const DrawIcon = createIcon(svg(SvgDraw), 'icon-draw');
export const RulerIcon = createIcon(svg(SvgRuler), 'icon-ruler');
export const PaletteIcon = createIcon(svg(SvgPalette), 'icon-palette');
export const BackIcon = createIcon(svg(SvgBack), 'icon-back');
export const QuestionMarkIcon = createIcon(svg(SvgQuestionMark), 'icon-question-mark');
export const UsageIcon = createIcon(svg(SvgUsage), 'icon-usage');
export const PlusIcon = createIcon(svg(SvgPlus), 'icon-plus');
export const AgenticAiIcon = createIcon(svg(SvgAgenticAi), 'icon-agentic-ai');
export const UploadIconIcon = createIcon(svg(SvgUploadIcon), 'icon-upload-icon');
export const BetaIcon = createIcon(svg(SvgBeta), 'icon-beta');
export const RotateIcon = createIcon(svg(SvgRotate), 'icon-rotate');
export const DeleteIcon = createIcon(svg(SvgDelete), 'icon-delete');
export const TFuelIcon = createIcon(svg(SvgTFuel), 'icon-tfuel');
export const DollarIcon = createIcon(svg(SvgDollar), 'icon-dollar');