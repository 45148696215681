export const getShortId = (id) => {
  //first three characters + 3 dots + last three characters
  return id.slice(0, 10) + '...' + id.slice(-6);
}


export const encodeData = (data) => {
  const salt = 'theta-video-api-frontend'; // A unique salt for this application
  const encoder = new TextEncoder();
  const jsonString = JSON.stringify(data);
  const encodedData = encoder.encode(jsonString);
  const saltArray = encoder.encode(salt);
  const combinedArray = new Uint8Array(saltArray.length + encodedData.length);
  combinedArray.set(saltArray);
  combinedArray.set(encodedData, saltArray.length);
  for (let i = 0; i < combinedArray.length; i++) {
    combinedArray[i] = combinedArray[i] ^ saltArray[i % saltArray.length];
  }
  const base64String = btoa(String.fromCharCode.apply(null, combinedArray));
  return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export const decodeData = (protectedString) => {
  const salt = 'theta-video-api-frontend'; // Same unique salt used for protection
  const decoder = new TextDecoder();
  const base64String = protectedString.replace(/-/g, '+').replace(/_/g, '/');
  const decodedArray = new Uint8Array(atob(base64String).split('').map(char => char.charCodeAt(0)));
  const saltArray = new TextEncoder().encode(salt);
  for (let i = 0; i < decodedArray.length; i++) {
    decodedArray[i] = decodedArray[i] ^ saltArray[i % saltArray.length];
  }
  const dataArray = decodedArray.slice(saltArray.length);
  const jsonString = decoder.decode(dataArray);
  return JSON.parse(jsonString);
}