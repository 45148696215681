import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { EllipsisIcon } from "../../../components/Svg";
import { ModalTypes, Urls } from "../../../constants";
import { AiTab, AiTabs } from '../../../components/AiTabs';
import UIState from '../../../store/UIState';
import Ai, {
  selectAllCustomTemplates,
  selectAllstandardTemplates,
  selectTotalCustomTempPage,
  selectTotalTempPage
} from '../../../store/models/Ai';
import { selectCurrentProjectId } from '../../../store/models/Project';
import { Pagination } from '../../../components/Pagination';
import classNames from 'classnames';
import { useLocation } from "react-router";
import { getQueryParams } from "../../../utils/url";
import { replaceTo } from "../../../store/history";
import ApiKeyNote from '../../../components/ApiKeyNote';
import { toast } from 'react-toastify';

const DateOptions = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true
};

function ModelExplorerPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { templateId } = getQueryParams(location.search)
  const projectId = useSelector(state => selectCurrentProjectId(state));
  const standardTemplates = useSelector(state => selectAllstandardTemplates(state));
  const customTemplates = useSelector(state => selectAllCustomTemplates(state, projectId));
  const totalTempPages = useSelector(state => selectTotalTempPage(state));
  const totalCustomTempPages = useSelector(state => selectTotalCustomTempPage(state));

  const [selectedTab, setSelectedTab] = useState('create-from-template')
  const [activePopup, setActivePopup] = useState(-1);
  const [crtTmpPage, setCrtTmpPage] = useState(1);
  const [crtCtmTmpPage, setCrtCtmTmpPage] = useState(1);

  useEffect(() => {
    if (templateId && !_.isEmpty(standardTemplates)) {
      replaceTo(Urls.AI_MODEL_EXPLORER, { projectId: projectId ?? 'service' })
      dispatch(UIState.actions.showModal(ModalTypes.CREATE_DEPLOYMENT, { modalId: templateId }))
    }
  }, [templateId, standardTemplates]);

  const handleOpenModel = (modalId) => {
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_DEPLOYMENT, { modalId }))
  }

  const handleOpenCustom = () => {
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_CUSTOM_TEMPLATE))
  }

  const handlePopup = (event, i) => {
    event.stopPropagation();

    if (i === -1) {
      setActivePopup(i)
      return;
    }
    setActivePopup(v => {
      if (v === i) return -1;
      return i;
    })
  }

  const handleEdit = (event, template) => {
    event.stopPropagation();
    setActivePopup(-1);
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_CUSTOM_TEMPLATE, { type: 'update', template: template }))
  }

  const handleDelete = (event, template) => {
    event.stopPropagation();
    setActivePopup(-1);
    const onConfirm = async () => {
      await dispatch(Ai.actions.deleteCustomTemplate(template.id, projectId));
      await dispatch(Ai.actions.fetchCustomTemplates(projectId, 1, 8));
    }
    dispatch(UIState.actions.showModal(ModalTypes.DELETE, { title: template.name, onConfirm }))
  }

  const handleDuplicate = (event, template) => {
    event.stopPropagation();
    setActivePopup(-1);
    const onConfirm = async () => {
      try {
        await dispatch(Ai.actions.createCustomTemplate({
          name: template.name + ' copy',
          project_id: projectId,
          description: template.description,
          container_image: template.container_images,
          tags: template.tags,
          container_port: template.container_port,
          container_args: template.container_args,
          env_vars: template.env_vars,
          icon_url: template.icon_url,
        }))
        await dispatch(Ai.actions.fetchCustomTemplates(projectId, 1, 8));
        toast.success(`Template ${template.name} copy has been created.`)
      } catch (e) {
        toast.error(`Oops, something went wrong. Error: ${e.message}`)
      }
    }
    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: `Duplicate custom template`,
      message: `Do you want to duplicate the ${template.name} template?`,
      confirmLabel: `Duplicate`,
      cancelLabel: `Close`,
      onConfirm
    }))
  }

  const handleTempPageChange = pageNumber => {
    setCrtTmpPage(pageNumber);
    dispatch(Ai.actions.fetchstandardTemplates(pageNumber, 9));
  }

  const handleCustomTempPageChange = pageNumber => {
    setCrtCtmTmpPage(pageNumber);
    dispatch(Ai.actions.fetchCustomTemplates(projectId, pageNumber, 8));
  }

  return (<div className={'AiServicePage'}>
    <div className={'AiServicePage__header'}>
      <div className={'AiServicePage__header--title'}>
        Model Explorer
        <ApiKeyNote type={'model deployments'} />
      </div>
      <div>
      </div>
    </div>
    <div className={'AiServicePage__content new-deployment'}>
      <AiTabs>
        <AiTab isSelected={(selectedTab === 'create-from-template')}
          onClick={() => {
            setSelectedTab('create-from-template');
          }}>
          Create from Standard Template
        </AiTab>
        <AiTab isSelected={(selectedTab === 'create-from-custom-template')}
          onClick={() => {
            setSelectedTab('create-from-custom-template');
          }}>
          Create from Custom Template
        </AiTab>
      </AiTabs>
      {
        (selectedTab === 'create-from-template') &&
        <div className='AiServicePage__tab-content'>
          <div className='ModelExplorerPage__row'>
            {standardTemplates.map((img, i) => {
              const logoName = (img.name || '').toLowerCase().replace('.', ' ').replace(' ', '-');
              return <div className={classNames('ModelExplorerPage__section',
                { new: img.rank && img.rank < 100, hot: img.rank && img.rank > 99 && img.rank < 200 })}
                onClick={() => handleOpenModel(img.id)} key={i}>
                <div className='ModelExplorerPage__section--title'>
                  <div className='ModelExplorerPage__section--name'>{img.name}</div>
                  <div className={`ModelExplorerPage__section--logo ${logoName}`}></div>
                </div>
                <div className='ModelExplorerPage__section--tags'>
                  {img.tags.map((tag, i) => {
                    return <Tag name={tag} key={i} />
                  })}
                </div>
                <div className='ModelExplorerPage__section--description'>
                  {img.description || "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat."}
                </div>
              </div>
            })}
            {standardTemplates.length % 3 === 2 && <div className='ModelExplorerPage__section'></div>}
          </div>
          <div className='ModelExplorerPage__pagination'>
            <Pagination
              size={'lg'}
              currentPage={crtTmpPage}
              totalPages={totalTempPages}
              onPageChange={handleTempPageChange} />
          </div>
        </div>
      }
      {
        (selectedTab === 'create-from-custom-template') &&
        <div className='AiServicePage__tab-content'>
          <div className='ModelExplorerPage__row'>
            {customTemplates.map((tmp, i) => {
              let date = new Date(tmp.create_time);
              const formattedDate = date.toLocaleString('en-US', DateOptions);
              return <div className='ModelExplorerPage__section' onClick={() => handleOpenModel(tmp.id)} key={tmp.id}>
                <div className='ModelExplorerPage__section--name custom'>{tmp.name}</div>
                <div className='ModelExplorerPage__section--time'>
                  {formattedDate}
                </div>
                <div className='ModelExplorerPage__section--tags'>
                  {tmp.tags.map((tag, i) => {
                    return <Tag name={tag} key={i} />
                  })}
                </div>
                <div className='ModelExplorerPage__section--description'>
                  {tmp.description || 'Empty custom template description.'}
                </div>
                <div className={'AiServicePageEllipsis ct'} onClick={(event) => handlePopup(event, i)}>
                  <EllipsisIcon />
                </div>
                {activePopup === i &&
                  <div className={'AiServicePageEllipsis__popup ct'} onClick={e => e.stopPropagation()}>
                    <div className={'AiServicePageEllipsis__popup--row'}
                      onClick={(event) => handleEdit(event, tmp)}>Edit
                    </div>
                    <div className={'AiServicePageEllipsis__popup--row'}
                      onClick={(event) => handleDuplicate(event, tmp)}>Duplicate
                    </div>
                    <div className={'AiServicePageEllipsis__popup--row'}
                      onClick={(event) => handleDelete(event, tmp)}>Delete
                    </div>
                  </div>}
              </div>
            })}
            <div className='ModelExplorerPage__section' onClick={handleOpenCustom}>
              <div className='ModelExplorerPage__section--add'>+</div>
            </div>
            {customTemplates.length % 3 === 1 && <div className='ModelExplorerPage__section'></div>}
          </div>
          <div className='ModelExplorerPage__pagination'>
            <Pagination
              size={'lg'}
              currentPage={crtCtmTmpPage}
              totalPages={totalCustomTempPages}
              onPageChange={handleCustomTempPageChange} />
          </div>
        </div>
      }
    </div>
  </div>
  );
}

export default ModelExplorerPage;

const Tag = ({ name }) => {
  let cssName = name.toLowerCase();
  if (/^\d/.test(cssName)) {
    cssName = '_' + cssName;
  }
  return <div className={`ModelExplorerPage__section--tag ${cssName}`}>
    {name}
  </div>
}