import _ from 'lodash';
import { authHeaders, get, post, put } from "../../utils/fetch";
import { Hosts, RequestState, Features, HardcodedStorageOrgsAllowed, DevEnv } from "../../constants";
import { selectCurrentUserId } from "./User";
import { EDIT_PROJECT } from "./Project";

export const getOrgColor = (orgId) => {
  const hash = orgId?.split('')?.reduce((acc, char) => char.charCodeAt(0) + acc, 0)
  return `hsl(${hash % 360}, 100%, 65%)`;
}

// ===========================
// ACTIONS
// ===========================
export const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION';
export const FETCH_USER_ORGANIZATIONS = 'FETCH_USER_ORGANIZATIONS';
export const FETCH_ORGANIZATION_USERS = 'FETCH_ORGANIZATION_USERS';
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const EDIT_ORG = 'EDIT_ORG';
export const FETCH_ORG_QUOTA = 'FETCH_ORG_QUOTA';
export const FETCH_ORG_USAGE = 'FETCH_ORG_USAGE';
export const FETCH_ORG_FEATURES = 'FETCH_WHITELISTED_ORGS';

// ===========================
// SELECTORS
// ===========================
export const selectCurrentOrgId = (state) => state?.app.orgId;
export const selectCurrentOrg = (state) => selectOrganization(state, selectCurrentOrgId(state));
export const selectOrganization = (state, organizationId) => {
  return state.models.organizations[organizationId];
};

export const selectOrganizations = (state) => _.orderBy(_.values(state.models.organizations), ['create_time'], ['asc']);
export const selectOrgQuota = (state, orgId) => state.models.orgQuota[orgId];
export const selectIsFeatureEnabled = (state, feature) => {
  return state.models.orgFeatures?.includes(feature);
};
export const selectFeaturesEnabled = (state) => {
  const features = state.models.orgFeatures || [];
  return features.reduce((obj, feature) => {
    obj[feature] = true;
    return obj;
  }, {});
};


// ===========================
// MODEL
// ===========================
const Organization = {
  actions: {
    fetchOrganization: (id) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/organization/:id`,
        params: {
          id
        },
        headers: authHeaders(),
        action: FETCH_ORGANIZATION,
        dispatch,
      });
      return _.get(result, 'body.organizations.0');
    },
    fetchUserOrganizations: (userId) => async (dispatch, getState) => {
      const result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/user/:userId/orgs`,
        params: {
          userId: userId ? userId : selectCurrentUserId(getState())
        },
        headers: authHeaders(),
        action: FETCH_USER_ORGANIZATIONS,
        dispatch,
      });
      return _.get(result, 'body.organizations');
    },
    createOrganization: (body) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: "/organization",
        headers: authHeaders(),
        action: CREATE_ORGANIZATION,
        dispatch,
        body: body
      });
      return _.get(result, 'body.organizations.0');
    },

    editOrg: (orgId, body) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: `/organization/:orgId`,
        headers: authHeaders(),
        action: EDIT_ORG,
        dispatch,
        params: { orgId },
        body: body
      });
      return _.get(result, 'body.organizations.0');
    },
    fetchOrgQuota: (orgId) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/quota/subscription`,
        params: {
          org_id: orgId
        },
        headers: authHeaders(),
        action: FETCH_ORG_QUOTA,
        dispatch,
      });
      return _.get(result, 'body.vms');
    },
    fetchOrgUsage: (orgId, startDate, endDate, filter, filterType) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: `/charge/usageHistory/${orgId}`,
        body: {
          startDate, endDate, filterType, filter
        },
        headers: authHeaders(),
        action: FETCH_ORG_USAGE,
        dispatch,
      });
      return _.get(result, 'body');
    },
    fetchWhitelistedOrgs: (orgId) => async (dispatch, getState) => {

      let result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/organization/:orgId/features`,
        params: { orgId },
        headers: authHeaders(),
        action: FETCH_ORG_FEATURES,
        dispatch,
      });

      return result.body.features;
    },
  },
  spec: {
    organizations: {},
    orgQuota: {},
    orgFeatures: [],
  },
  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;

    if (!_.isEmpty(body?.organizations)) {
      return {
        ...state,
        organizations: {
          ...state.organizations,
          ..._.keyBy(body.organizations, 'id'),
        }
      }
    }

    if (!_.isEmpty(body?.vms)) {
      return {
        ...state,
        orgQuota: {
          ...state.orgQuota,
          [action.params.org_id]: body.vms
        }
      }
    }

    if (type === FETCH_ORG_FEATURES) {
      return {
        ...state,
        orgFeatures: _.map(body.features, feature => feature.id)
      }
    }

    return state;
  }
}
export default Organization;