import React, { useEffect } from "react";
import { AiIcon, RenderingIcon, VideoIcon } from "../../components/Svg";
import cx from "classnames";
import { Urls } from "../../constants";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentOrg, selectCurrentOrgId } from "../../store/models/Organization";
import { createSelector } from "reselect";
import { replaceTo } from "../../store/history";
import { selectCurrentProjectId } from "../../store/models/Project";
import _ from "lodash";
import { mergeUrl } from "../../utils/url";
import { selectCurrentUserId } from "../../store/models/User";
import { selectBalance } from "../../store/models/Bill";
import { Overlay } from "../../components/Overlay";
import { selectMobileLayout } from "../../store/UIState";
import { Button } from "../../components/Button";
import { toast } from "react-toastify";
import useSelectDefaultProject from "../../hooks/useSelectDefaultProject";

const selector = createSelector(
  selectCurrentUserId,
  selectCurrentOrgId,
  selectCurrentProjectId,
  selectCurrentOrg,
  selectMobileLayout,
  (userId, currentOrgId, currentProjectId,
    currentOrg, isMobile) => (
    {
      userId, currentOrgId, currentProjectId: currentProjectId || 'service',
      currentOrg, isMobile
    }
  )
)
export const DashboardPage = () => {

  const { userId, currentOrgId, currentProjectId,
    currentOrg, isMobile } = useSelector(state => selector(state));
  const { fetchDefaultProject } = useSelectDefaultProject();
  useEffect(() => {
    if (_.isNil(userId)) {
      return;
    }
    if (_.isNil(currentOrgId)) {
      fetchDefaultProject();
    }
  }, [currentOrgId]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.origin);
    toast.success("Copied to clipboard")
  }

  return (<div className={'ServiceContainer'}>
    {isMobile &&
      <div className={"LandingPage"}>
        <div className={"main-wrapper"}>

          <div className={"bg-image home"} />
          <div className="warning-title">
            This page is optimized for desktop. Please try on desktop for a better experience.
          </div>
          <div className="warning-link">
            {window.location.origin.replace(/thetaedgecloud/g, "ThetaEdgeCloud")}
            <Button title={"Copy Link"} className={"sign-up-btn"} size={"l"} onClick={handleCopyLink} />
          </div>
        </div>
      </div>}
    {!isMobile && <div className={'ServicePage'}>
      {(!userId || (currentOrg && !currentOrg.disabled)) &&
        <div className={'DashboardPage__Content'}>
          <DashboardServiceItem title={'AI Services'}
            icon={<AiIcon />}
            link={mergeUrl(Urls.AI_MODEL_EXPLORER, { projectId: currentProjectId })}
            thumbnail={"/images/dashboard/thumbnail_ai_services.png"} />
          <DashboardServiceItem title={'Video Services'}
            icon={<VideoIcon />}
            link={userId ? Urls.DASHBOARD_VIDEO : Urls.VIDEO_OVERVIEW}
            thumbnail={"/images/dashboard/thumbnail_video_services.png"} />
          <DashboardServiceItem title={'Rendering/Gaming Services'}
            icon={<RenderingIcon />}
            link={mergeUrl(Urls.DASHBOARD_RENDERING, { projectId: currentProjectId })}
            thumbnail={"/images/dashboard/thumbnail_coming_soon.png"} />
        </div>}
      {currentOrg && currentOrg.suspended && <Overlay type={'suspended'} />}
      {currentOrg && currentOrg.disabled && <Overlay type={'disabled'} />}
    </div>}
  </div>
  )
}

const DashboardServiceItem = ({ title, icon, thumbnail, link, disabled }) => {
  return (
    <Link to={link ? link : '#'}>
      <div className={cx('DashboardPageItem', { disabled })}>
        <div className={'DashboardPageItem__Thumbnail'}>
          <img src={thumbnail} alt={title} />
        </div>
        <div className={cx('DashboardPageItem__IconContainer', { disabled })}>
          {icon}
        </div>
        <div className={'DashboardPageItem__Title'}>
          {title}
        </div>
      </div>
    </Link>
  )
}