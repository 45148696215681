import React from 'react';
import cx from 'classnames';
import {useDispatch} from 'react-redux';
import {createSelector} from "reselect";
import {Link} from 'react-router-dom';

import {Urls} from '../constants';


import Scrollbars from '../components/Scrollbars';
import Overlay from '../components/MenuOverlay';

import {
  CloseIcon,
} from "./Svg";
import dayjs from "dayjs";


const CURRENT_YEAR = dayjs().get('year')

export const SideMenuItem = ({className, disabled, to = '/', partnerTo, url, current, ...props}) => {
  if (url)
    return <a href={url} className={cx("g-side-menu-item", className, {disabled})} {...props} />
  return <Link className={cx("g-side-menu-item", className, {disabled, current})} to={to} {...props} />
}

export const SideMenu = ({children, className, open, onClose, ...props}) => {
  let onOverlayClick = () => onClose()
  return (<>
    <div className={cx("g-side-menu", className, {open})} onClick={onOverlayClick}>
      <Scrollbars>
        {children}
      </Scrollbars>
    </div>
    {open &&
    <Overlay onClick={onOverlayClick}/>}
  </>);
}
export default SideMenu;


// ===========================
// TYPES
// ===========================

export const MobileSideMenu = ({user, partnerSlug, ...props}) => {
  const page = window.location.pathname;

  return (
    <SideMenu className="partner" open={false}{...props}>
      <div className="sidemenu-wrap">
        <div className="sidemenu-items">
          <SideMenuItem to={page}><CloseIcon className='close-icon'/></SideMenuItem>
          <SideMenuItem to={Urls.HOME} current={page === '/'}>Home
            {page === '/' &&
            <div className='border'/>}
          </SideMenuItem>

        </div>

        <div className="bottom-section">
          <div className="copyright">Copyright © {CURRENT_YEAR} Theta Labs, Inc All rights reserved.</div>
        </div>
      </div>
    </SideMenu>)
}