import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { BackArrowIcon, CopyIcon } from "../../../components/Svg";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Hosts, Urls
} from "../../../constants";
import { mergeUrl } from "../../../utils/url";
import { AiTabs, AiTab } from '../../../components/AiTabs';
import Ai, { getRagChatbotIframeUrl, selectRagChatbot } from '../../../store/models/Ai';
import cx from 'classnames';
import Loader from '../../../components/Loader';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Button } from '../../../components/Button';
import { toast } from 'react-toastify';
import { KnowledgeTab } from '../../../components/rag-chatbot/KnowledgeTab';
import { RagSettingsTab } from '../../../components/rag-chatbot/RagSettingsTab';


function RagChatbotDetailPage() {
  const dispatch = useDispatch();
  const { projectId, id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [selectedSubTab, setSelectedSubTab] = useState('playground');
  const [accessToken, setAccessToken] = useState();

  const chatbot = useSelector(state => selectRagChatbot(state, projectId, id));

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [projectId, id]);

  const fetchData = async () => {
    setLoading(true);
    if (error) setError();
    try {
      await dispatch(Ai.actions.fetchRagChatbot(projectId, id));
      let res = await dispatch(Ai.actions.fetchRagAccessToken(projectId, id));
      setAccessToken(res);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }



  return (<div className={'AiServicePage'}>
    <Link to={mergeUrl(Urls.AI_RAG_CHATBOT, { projectId })}>
      <div className={'AiServicePage__back-button rag-chatbot'}>
        <BackArrowIcon />
        <div className={'AiServicePage__back-button--label'}>
          RAG Chatbot
        </div>
      </div>
    </Link>
    {!loading && chatbot && <div className={'AiServicePage__header detail'}>
      <div className={'AiServicePage__header--title'}>{chatbot.name || 'RAG Chatbot'}</div>
    </div>}
    {!loading && chatbot && <div className={'AiServicePage__content new-deployment'}>
      <AiTabs>
        <AiTab isSelected={(selectedSubTab === 'playground')}
          onClick={() => {
            setSelectedSubTab('playground')
          }}>
          Playground
        </AiTab>
        <AiTab isSelected={(selectedSubTab === 'integration')}
          onClick={() => {
            setSelectedSubTab('integration')
          }}>
          Integration
        </AiTab>
        <AiTab isSelected={(selectedSubTab === 'settings')}
          onClick={() => {
            setSelectedSubTab('settings')
          }}>
          Settings
        </AiTab>
        <AiTab isSelected={(selectedSubTab === 'knowledge-base')}
          onClick={() => {
            setSelectedSubTab('knowledge-base')
          }}>
          Knowledge base
        </AiTab>
      </AiTabs>
      {
        (selectedSubTab === 'playground') && chatbot &&
        <PlaygroundTab chatbot={chatbot} accessToken={accessToken} />
      }
      {
        (selectedSubTab === 'integration') && chatbot &&
        <IntegrationTab chatbot={chatbot} accessToken={accessToken} />
      }
      {
        (selectedSubTab === 'settings') && chatbot &&
        <div className='AiServicePage__tab-content'>
          <RagSettingsTab chatbot={chatbot} projectId={projectId} accessToken={accessToken} setAccessToken={setAccessToken} />
        </div>
      }
      {
        (selectedSubTab === 'knowledge-base') && chatbot &&
        <KnowledgeTab chatbot={chatbot} />
      }
    </div>}
    {loading && <div className={'EmptyState'}>
      <Loader size='large' color='grey' />
    </div>}
    {!loading && error && !chatbot && <div className={"AiServicePage__error"}>
      {error}
      <Button onClick={fetchData}
        color={"green-outline"}
        title={"Retry"} />
    </div>}
  </div >
  );
}

const PlaygroundTab = (props) => {
  const { chatbot, accessToken } = props;

  const handleThemeChange = (event) => {
    setTheme(event.target.value);
  };

  const iframeUrl = useMemo(() =>
    getRagChatbotIframeUrl(chatbot, accessToken), [chatbot.llm_endpoint, accessToken]);

  const onCopy = () => {
    navigator.clipboard.writeText(iframeUrl)
      .then(() => {
        toast.success('Chatbot URL Copied!')
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  }
  return <div className='AiServicePage__tab-content'>
    <div className='RagChatbotDetailPage playground'>
      <div className='RagChatbotDetailPage__row'>

        <div className='RagChatbotDetailPage__input-wrap'>
          <div className='RagChatbotDetailPage__input-label-row'>
            <div className='RagChatbotDetailPage__input-label'>
              Chatbot URL <div className='RagChatbotDetailPage__input-label--icon' onClick={onCopy}><CopyIcon /></div>
            </div>
            <Button href={iframeUrl} target='_blank' title={"Open"} size={'small'} color={'green-outline'} />
          </div>

          <div className='RagChatbotDetailPage__input-div'>
            <div className='RagChatbotDetailPage__input-div--info RagChatbotDetailPage__input-div--one-line'>{iframeUrl}</div>
          </div>
        </div>
      </div>
      <div className='RagChatbotDetailPage__playground'>
        <iframe src={iframeUrl} width='100%' height='100%' />
      </div>
    </div>
  </div >
}



const IntegrationTab = (props) => {
  const { chatbot, accessToken } = props;
  const [tab, setTab] = useState('curl');

  return (
    <div className='AiServicePage__tab-content'>
      <div className='RagChatbotDetailPage integration'>
        <div className='RagChatbotDetailPage__tabs'>
          <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'curl' })} onClick={() => setTab('curl')}>
            cURL
          </div>
          {/* <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'js' })} onClick={() => setTab('js')}>
            JavaScript
          </div>
          <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'python' })} onClick={() => setTab('python')}>
            Python
          </div> */}
          <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'iframe' })} onClick={() => setTab('iframe')}>
            iFrame
          </div>
        </div>
        <CodeIntegration tab={tab} chatbot={chatbot} accessToken={accessToken} />
      </div>
    </div>
  );
}

const CodeIntegration = ({ tab, chatbot, accessToken }) => {
  const { model, llm_endpoint, max_tokens, id, temperature } = chatbot;
  const link = (llm_endpoint || '').split('/v1')[0];
  const iframeUrl = getRagChatbotIframeUrl(chatbot, accessToken);

  const [copied, setCopied] = useState(false);
  const [code, setCode] = useState(`curl -X POST ${Hosts.CHATBOT_API}/chatbot/${id}/chat/completions \\
  -H 'Content-Type: application/json' \\
  -H "Authorization: Bearer ${accessToken}" \\
  -d '{
    "messages": [
        {"role": "system", "content": "You are a helpful assistant."},
        {"role": "user", "content": "Hello, how are you?"}
    ],
  }'`);
  useEffect(() => {
    const curlCode = `curl -X POST ${Hosts.CHATBOT_API}/chatbot/${id}/chat/completions \\
    -H 'Content-Type: application/json' \\
    -H "Authorization: Bearer ${accessToken}" \\
    -d '{
      "messages": [
          {"role": "system", "content": "You are a helpful assistant."},
          {"role": "user", "content": "Hello, how are you?"}
      ],
      "max_tokens": ${max_tokens || 100},
      "temperature": ${temperature || 0.7},
      "stream": true
    }'`;
    const jsCode = `import OpenAI from "openai";

// Set OpenAI's API key to use vLLM's API server.
const openai_api_key = "YOUR_OPENAI_API_KEY"
const openai_api_base = "${link}/v1"

const openai = new OpenAI(
  api_key=openai_api_key,
  base_url=openai_api_base,
);

async function main() {
  const completion = await openai.completions.create({
    "model": "${model}",
    "messages": [
      {"role": "system", "content": "You are a helpful assistant."},
      {"role": "user", "content": "Hello, how are you?"}
    ],
    "max_tokens": ${max_tokens || 100}
  });
    
  // console.log(completion);
}
main();`;

    const pythonCode = `from openai import OpenAI

# Set OpenAI's API key to use vLLM's API server.
openai_api_key = "YOUR_OPENAI_API_KEY"
openai_api_base = "${link}/v1"

client = OpenAI(
  api_key=openai_api_key,
  base_url=openai_api_base,
)

chat_response = client.chat.completions.create({
  "model": "${model}",
  "messages": [
    {"role": "system", "content": "You are a helpful assistant."},
    {"role": "user", "content": "Hello, how are you?"}
  ],
  "max_tokens": ${max_tokens || 100}
})
print("Chat response:", chat_response)`;

    const iframeCode = `<iframe src="${iframeUrl}"></iframe>`;

    switch (tab) {
      case 'curl':
        setCode(curlCode);
        break;
      case 'js':
        setCode(jsCode);
        break;
      case 'python':
        setCode(pythonCode);
        break;
      case 'iframe':
        setCode(iframeCode);
        break;
      default:
        setCode();
        break;
    }
  }, [tab, chatbot]);

  function getLanguage(tab) {
    switch (tab) {
      case 'curl':
        return 'javascript';
      case 'js':
        return 'javascript';
      case 'python':
        return 'python';
      case 'iframe':
        return 'html';
      default:
        return 'javascript';
    }
  }

  function getTitle(tab) {
    switch (tab) {
      case 'curl':
        return 'cURL code';
      case 'js':
        return 'JavaScript code';
      case 'python':
        return 'Python code';
      case 'iframe':
        return 'iFrame embed';
      default:
        return 'javascript';
    }
  }

  const handleCopy = () => {
    if (copied) return;
    navigator.clipboard.writeText(code)
      .then(() => {
        setCopied(true);
        toast.success('Code Copied!')
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  }

  return <div className='AiServicePage__tab-content'>
    <div className='RagChatbotDetailPage integration'>
      <div className='RagChatbotDetailPage__code-content'>
        <div className='RagChatbotDetailPage__code-content--title'>{getTitle(tab)}</div>
        <div className='RagChatbotDetailPage__code-content--code'>
          <SyntaxHighlighter
            lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
            language={getLanguage(tab)}
            style={vscDarkPlus}>
            {code}
          </SyntaxHighlighter>
        </div>
        <Button onClick={handleCopy} title={copied ? "Code Copied!" : "Copy Code"} color={'green-outline'} />

      </div>
    </div>
  </div>
}
export default RagChatbotDetailPage;
