import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { DailyChart, HourChart } from '../../components/Chart';
import MiniChart from '../../components/MiniChart';
import { getBarByCardName, getBarCard, getChartDataByBar, getChartType } from '../../utils/index';
import { BreakDownCategory, BreakDownList, DevEnv, LeftBarNames } from '../../constants';
import { history } from '../../store/history';
import Metric, { selectMetric } from '../../store/models/Metric';
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "../../components/Tabs";
import PageTitleBar from "../../components/PageTitleBar";
import { BackButton, Button } from '../../components/Button';
import { selectCurrentProject } from "../../store/models/Project";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Loader from '../../components/Loader';

function Metrics() {
  const dispatch = useDispatch();
  const metric = useSelector(state => selectMetric(state)) || {};
  // console.log(`jlog142 metric is ${JSON.stringify(metric)}`);
  const project = useSelector(state => selectCurrentProject(state));
  const videoApiId = project?.tva_id;
  const downloadRef = useRef();
  const currentDate = new Date();
  const iniStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
  const [startDate, setStartDate] = useState(iniStartDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [loading, setLoading] = useState(false);
  const [isDateUpdated, setIsDateUpdated] = useState(false);

  // console.log('metrics')
  // console.log(project)
  // console.log(videoApiId)

  // console.log(`jlog11`, metric);
  const [activeInfo, setActiveInfo] = useState({
    bar: 'URL Viewers',
    // card: 'Overall Viewer Experience',
    card: 'URL Viewers',
    // tab: new URLSearchParams(history.location.search).get('activeTab') || 'breakdown',
    tab: new URLSearchParams(history.location.search).get('activeTab') || 'description',
    chart_type: 2,
  });

  useEffect(() => {
    fetchData();
  }, [history.location.search])

  useEffect(() => {
    setIsDateUpdated(true);
  }, [startDate, endDate])

  const fetchData = async () => {
    const search = history.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const activeBar = params.get('metric');
    const activeBarName = LeftBarNames[activeBar] || "URL Viewers";
    const activeCard = getBarCard(activeBar);
    const chart_type = getChartType(activeBar);
    const videoId = params.get('videoId');
    const streamId = params.get('streamId');
    let sDate = moment(startDate).format('YYYY-MM-DD')
    let eDate = moment(endDate).format('YYYY-MM-DD')
    // const 
    setActiveInfo({ bar: activeBarName, card: activeCard, tab: activeInfo.tab, chart_type: chart_type });
    setLoading(true);
    try {
      if (videoId) {
        // fetch metrics by video ID
        if (DevEnv == 'prod' || DevEnv == 'staging') {
          await dispatch(Metric.actions.fetchMetricsTVAID(videoId, activeBarName, sDate, eDate));
        } else {
          await dispatch(Metric.actions.fetchMetricsTVAID('video_1sg0v1ccy0xyqbpjhdcgk5ywdp', activeBarName, sDate, eDate)); //for testing
        }
      } else if (streamId) {
        // fetch metrics by stream ID
        if (DevEnv == 'prod' || DevEnv == 'staging') {
          await dispatch(Metric.actions.fetchMetricsTVAID(streamId, activeBarName, sDate, eDate));
        } else {
          await dispatch(Metric.actions.fetchMetricsTVAID('stream_z58i160ccwykbqyf3hi0xdvwn', activeBarName, sDate, eDate)); //for testing
        }
      } else {
        // fetch overall data
        if (DevEnv == 'prod' || DevEnv == 'staging') {
          await dispatch(Metric.actions.fetchMetricsServiceAccountID(videoApiId, activeBarName));
        } else {
          await dispatch(Metric.actions.fetchMetricsServiceAccountID('srvacc_edrjamqcpwwiqzu82k6u6p1nw', activeBarName)); //for testing
        }
      }
    } catch (e) {

    } finally {
      setLoading(false);
      setIsDateUpdated(false);
    }

  }

  const handleOnClick = (tab) => {
    let searchParams = new URLSearchParams(history.location.search);
    // returns the existing query string: '?type=fiction&author=fahid'
    searchParams.set('activeTab', tab);
    history.replace({ search: searchParams.toString() })

    // history.replace({ search: 'activeTab=' + tab })
    setActiveInfo({ ...activeInfo, tab: tab });
  }
  // TODO: provide more details or use breakDown tab
  const DescriptionPane = () => {
    const params = new URLSearchParams(history.location.search);
    const videoId = params.get('videoId');
    const streamId = params.get('streamId');
    // videoApiId
    let descriptionStr = activeInfo.bar;

    if (videoId) {
      descriptionStr += " of video " + videoId;
    } else if (streamId) {
      descriptionStr += " of stream " + streamId;
    } else {
      descriptionStr += " of all videos / streams ";
    }

    return <div className='Metrics__title'>
      {/* <div className='Metrics__content'>Description here</div> */}
      <div className='Metrics__card-header--value'>{descriptionStr}</div>
    </div>
  }
  const leftContent = () => {
    const params = new URLSearchParams(history.location.search);
    const videoId = params.get('videoId');
    const streamId = params.get('streamId');
    let title = 'Apps', href = `/`;
    if (videoId) {
      title = 'Video';
      href = `/dashboard/video/videos/${videoId}`
    } else if (streamId) {
      title = 'Stream';
      href = `/dashboard/video/livestreams/${streamId}`
    }
    return <BackButton title={title} href={href} />
  }
  const handleDownload = () => {
    function convertToCSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';
      var line = '';
      for (var index in array[0]) {
        if (line != '') line += ','
        line += index;
      }
      str += line + '\r\n';
      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
          if (line != '') line += ','

          line += array[i][index];
        }

        str += line + '\r\n';
      }
      return str;
    }
    var csv = convertToCSV(_.get(metric, getChartDataByBar(activeInfo.bar)).map(o => {
      if (activeInfo.bar == 'URL Hits') {
        return {
          date: o.date,
          'result(K hits)': Number((o.result / 1000).toFixed(2))
        };
      }
      if (activeInfo.bar == 'URL Volume') {
        return {
          date: o.date,
          'result(GB)': Number((o.result / 1000000000).toFixed(2))
        };
      }
      return { date: o.date, result: o.result };
    }));

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `${activeInfo.bar}.csv`);
    a.click();
    window.URL.revokeObjectURL(url);
  }
  return (
    <div className={'ServiceContainer'}>
      <div className={'ServicePage'}>

        <div className={'ServicePage__content'}>
          <div className="Metrics">
            <div className='Metrics__title'>
              <PageTitleBar title={'Metrics'}
                leftContent={leftContent()}
              />
            </div>
            <div className='Metrics__content'>
              <div className='Metrics__navbar'>
                {/* <NavbarCard name='Views' value={metric['views'].score} data={metric['views'].data} activeCard={activeInfo.card} activeBar={activeInfo.bar}> */}
                {/* <NavbarCard name='Views'
          value={_.get(metric, 'views.score')}
          data={_.get(metric, 'views.data')}
          activeCard={activeInfo.card}
          activeBar={activeInfo.bar}>
          <div className={'Metrics__subbar-wrap'}>
            <Subbar name='Views' activeBar={activeInfo.bar} />
            <Subbar name='Unique Viewers' activeBar={activeInfo.bar} />
            <Subbar name='Playing Time' activeBar={activeInfo.bar} />
          </div>
        </NavbarCard>
        <div className='Metrics__navbar--title'>
          QUALITY OF EXPERIENCE
        </div> */}
                <NavbarCard name='URL Viewers'
                  // value={_.get(metric, 'akamai_hits.result')}
                  unit=''
                  data={_.get(metric, 'akamai_hits.data')}
                  serviceAccountID={videoApiId}
                  activeCard={activeInfo.card}
                  activeBar={activeInfo.bar} />
                <NavbarCard name='URL Hits'
                  // value={_.get(metric, 'akamai_hits.result')}
                  unit='K hits'
                  data={_.get(metric, 'akamai_hits.data')}
                  serviceAccountID={videoApiId}
                  activeCard={activeInfo.card}
                  activeBar={activeInfo.bar} />
                <NavbarCard name='URL Volume'
                  // value={_.get(metric, 'akamaiVolume.result')}
                  unit='GB'
                  data={_.get(metric, 'akamaiVolume.data')}
                  serviceAccountID={videoApiId}
                  activeCard={activeInfo.card}
                  activeBar={activeInfo.bar} />
                {/*
        <NavbarCard name='Overall Viewer Experience'
          value={_.get(metric, 'viewer_experience_score.score')}
          data={_.get(metric, 'viewer_experience_score.data')}
          activeCard={activeInfo.card}
          activeBar={activeInfo.bar} />
        <NavbarCard name='Playback Success'
          value={_.get(metric, 'playback_success_score.score')}
          data={_.get(metric, 'playback_success_score.data')}
          activeCard={activeInfo.card}
          activeBar={activeInfo.bar} >
          <div className={'Metrics__subbar-wrap'}>
            <Subbar name='Playback Success Score' activeBar={activeInfo.bar} />
            <Subbar name='Playback Failure Percentage' activeBar={activeInfo.bar} />
            <Subbar name='Exits Before Video Start' activeBar={activeInfo.bar} />
          </div>
        </NavbarCard>
        <NavbarCard name='Startup Time'
          value={_.get(metric, 'startup_time_score.score')}
          data={_.get(metric, 'startup_time_score.data')}
          activeCard={activeInfo.card}
          activeBar={activeInfo.bar} >
          <div className={'Metrics__subbar-wrap'}>
            <Subbar name='Startup Time Score' activeBar={activeInfo.bar} />
            <Subbar name='Video Startup Time' activeBar={activeInfo.bar} />
            <Subbar name='Player Startup Time' activeBar={activeInfo.bar} />
          </div>
        </NavbarCard>
        <NavbarCard name='Smoothness'
          value={_.get(metric, 'smoothness_score.score')}
          data={_.get(metric, 'smoothness_score.data')}
          activeCard={activeInfo.card}
          activeBar={activeInfo.bar} >
          <div className={'Metrics__subbar-wrap'}>
            <Subbar name='Smoothness Score' activeBar={activeInfo.bar} />
            <Subbar name='Rebuffer Count' activeBar={activeInfo.bar} />
          </div>
        </NavbarCard>
        <NavbarCard name='Video Quality'
          value={_.get(metric, 'video_quality_score.score')}
          data={_.get(metric, 'video_quality_score.data')}
          activeCard={activeInfo.card}
          activeBar={activeInfo.bar} >
          <div className={'Metrics__subbar-wrap'}>
            <Subbar name='Video Quality Score' activeBar={activeInfo.bar} />
            <Subbar name='Upscale Percentage' activeBar={activeInfo.bar} />
          </div>
        </NavbarCard> */}
              </div>
              {(activeInfo.chart_type == 1) && <div className='Metrics__chart'>
                <div className='Metrics__chart--header'>
                  <div className='Metrics__chart--title'>
                    <div className='Metrics__chart--value'>
                      <span className='left'>{_.get(metric, `${getCap(activeInfo.bar)}.score`) || []}</span>
                    </div>
                    <div className='Metrics__chart--text'>{activeInfo.bar || "Overall Viewer Experience Score"}</div>

                  </div>
                  <div className='Metrics__chart--info'>
                    <div className='Metrics__chart--value'>
                      {_.get(metric, 'views.score')}
                    </div>
                    <div className='Metrics__chart--text'>
                      Total Video Views
                      <p>(Last 24 hours)</p>
                    </div>
                  </div>
                </div>
                <div className='Metrics__chart--container'>
                  <HourChart data={_.get(metric, `${getCap(activeInfo.bar)}`)}
                    legendName={activeInfo.bar || 'Overall Viewer Experience Score'} />
                </div>
                <div className='Metrics__chart--filter'>
                  <Tabs>
                    <Tab isSelected={(activeInfo.tab === 'description')}
                      onClick={() => handleOnClick('description')}>Description</Tab>
                    {/* <Tab isSelected={(activeInfo.tab === 'breakdown')} onClick={() => handleOnClick('breakdown')}>BreakDown</Tab> */}
                  </Tabs>
                  {(activeInfo.tab === 'description') && <DescriptionPane />}
                  {(activeInfo.tab === 'breakdown') && <BreakDownPane />}
                </div>
              </div>}
              {/* for Akamai info(daily)  */}
              {(activeInfo.chart_type == 2) && <div className='Metrics__chart'>
                <div className='Metrics__chart--header'>
                  {/* <div className='Metrics__chart--title'>
            <div className='Metrics__chart--value'>
              <span className='left'>{_.get(metric, `${getCap(activeInfo.bar)}.resul}</span>
            </div>
            <div className='Metrics__chart--text'>{activeInfo.bar || "Overall Viewer Experience Score"}</div>

          </div> */}
                  {/* <div className='Metrics__chart--info'>
                    <div className='Metrics__chart--value'>
                      {_.get(metric, 'views.score')}
                    </div>
                    <div className='Metrics__chart--text'>
                      Total Video Views
                      <p>(Last 24 hours)</p>
                    </div>
                  </div> */}
                  <div className='Metrics__chart--download'>
                    <div className='Metrics__download--wrap'>
                      <div className='Metrics__download--label'>Start Date</div>
                      <DatePicker selected={startDate}
                        onChange={setStartDate}
                        disabled={loading}
                        maxDate={endDate} />
                    </div>
                    <div className='Metrics__download--wrap'>
                      <div className='Metrics__download--label'>End Date</div>
                      <DatePicker selected={endDate}
                        onChange={setEndDate}
                        disabled={loading}
                        minDate={startDate}
                        maxDate={currentDate} />
                    </div>
                    <div className='Metrics__download--wrap button'>
                      <div className='Metrics__download--label'>&nbsp;</div>
                      {(isDateUpdated & !loading) ?
                        <Button title={"Update chart"} color='green' onClick={fetchData} /> :
                        <Button title={loading ? 'Loading' : "Download data"} color='green-outline'
                          onClick={handleDownload} disabled={loading || !_.get(metric, getChartDataByBar(activeInfo.bar))} />}
                    </div>
                  </div>
                </div>
                <div className='Metrics__chart--container'>
                  {loading ? <Loader color='green' size='large' /> :
                    <DailyChart data={_.get(metric, `${getCap(activeInfo.bar)}`) || []}
                      legendName={activeInfo.bar || 'URL Viewers'} />}
                </div>
                <div className='Metrics__chart--filter'>
                  <Tabs>
                    <Tab isSelected={(activeInfo.tab === 'description')}
                      onClick={() => handleOnClick('description')}>Description</Tab>
                    {/* <Tab isSelected={(activeInfo.tab === 'breakdown')} onClick={() => handleOnClick('breakdown')}>BreakDown</Tab> */}
                  </Tabs>
                  {(activeInfo.tab === 'description') && <DescriptionPane />}
                  {(activeInfo.tab === 'breakdown') && <BreakDownPane />}
                </div>
              </div>}

            </div>
          </div>

        </div>
      </div>
    </div>)
}

const NavbarCard = (props) => {
  const { name, value, activeCard, activeBar, children, data, legendName, serviceAccountID, unit } = props;
  // console.log(`jlog12 activeCard ${activeCard} activeBar ${activeBar}`)
  const handleOnClick = (e) => {
    e.preventDefault();
    const barName = getBarByCardName(name);
    if (barName === activeBar.toLowerCase().replaceAll(' ', '_')) return;
    // history.replace({ search: 'metric=' + barName })
    let searchParams = new URLSearchParams(history.location.search);
    searchParams.set('metric', barName);
    history.replace({ search: searchParams.toString() })
  }
  return <div className={`Metrics__leftbar ${activeCard === name ? 'active' : ''}`}>
    <div onClick={handleOnClick}>
      <div className={`Metrics__card-header`}>
        <div className='Metrics__card-header--name'>{name}</div>
        {/* <div className='Metrics__card-header--value'>{value ? value : '..'}</div> */}
        {/* <div className='Metrics__card-header--value'>{unit ? unit : '..'}</div> */}
        <div className='Metrics__card-header--value'>{unit}</div>
      </div>
      <div className='Metrics__card-chart'>
        <MiniChart data={data} legendName={legendName} active={activeCard === name} />
      </div>
    </div>
    {children}
  </div>
}

const Subbar = (props) => {
  const { name, activeBar } = props;
  const handleOnClick = () => {
    const barName = name.toLowerCase().replaceAll(' ', '_');
    if (LeftBarNames[barName] === activeBar) return;
    history.replace({ search: 'metric=' + barName })
  }
  return <div
    className={`Metrics__subbar ${activeBar === name ? 'active' : ''}`}
    onClick={handleOnClick}>
    {name}
  </div>
}

const getCap = (str) => {
  if (str === 'Overall Viewer Experience') return 'viewer_experience_score';
  if (str == 'URL Hits') {
    return 'akamai_hits';
  }
  if (str == 'URL Volume') {
    return 'akamai_volume';
  }
  if (str == 'URL Viewers') {
    return 'url_viewers';
  }
  return str.toLowerCase().replaceAll(' ', '_')
}

const getBreakDownText = (str) => {
  const arr = str.split("_");
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

  }
  return arr.join(" ");
}


const BreakDownPane = () => {
  const handleOnClick = (e, breakdown) => {
    e.preventDefault();
    let searchParams = new URLSearchParams(history.location.search);
    // returns the existing query string: '?type=fiction&author=fahid'
    searchParams.set('breakdown', breakdown);
    history.replace({ search: searchParams.toString() })
  }

  const handleClickTitle = (e, type) => {
    e.preventDefault();
    let searchParams = new URLSearchParams(history.location.search);
    let curType = searchParams.get('order_by') || 'negative_impact';
    let curDir = searchParams.get('order_direction') || 'asc';

    searchParams.set('order_by', type);
    searchParams.set('order_direction', curType === type ? (curDir === 'asc' ? 'desc' : 'asc') : 'desc');

    history.replace({ search: searchParams.toString() });
  }


  return <div className='Metrics__breakdown'>
    <div className='Metrics__breakdown--navbar'>
      {BreakDownCategory.map((category, i) => {
        return <div key={i} className="breakdown-navbar">
          <div className='breakdown-navbar__title'>
            <div className='breakdown-navbar__icon'></div>
            {category.replaceAll('_', ' ').toUpperCase()}
          </div>
          {BreakDownList[category].map((breakdown, j) => {
            return <div key={j}
              className={`breakdown-navbar__breakdown ${getCurrentBreakDown() === breakdown ? 'active' : ''}`}
              onClick={(e) => handleOnClick(e, breakdown)}>
              <div className='breakdown-navbar__star'></div>
              {getBreakDownText(breakdown)}
            </div>
          })}
        </div>
      })}
    </div>
    <div className='Metrics__breakdown--content'>
      <div className='Metrics__filter--header'>
        <div className='Metrics__filter--name'>{getBreakDownText(getCurrentBreakDown()).toUpperCase()}</div>
        <div className='Metrics__filter--title lg' onClick={(e) => handleClickTitle(e, 'value')}>
          Overall Viewer Experience Score (Average)
          <div className='Metrics__filter--sort'>
            {getCurrentSortType() === 'value' ?
              (getCurrentSortDir() === 'desc' ? <div className='arrow-down'></div> : <div className='arrow-up'></div>) :
              <div className='arrow-down-up'></div>}
          </div>
        </div>
        <div className='Metrics__filter--title' onClick={(e) => handleClickTitle(e, 'views')}>
          Total Views
          <div className='Metrics__filter--sort'>
            {getCurrentSortType() === 'views' ?
              (getCurrentSortDir() === 'desc' ? <div className='arrow-down'></div> : <div className='arrow-up'></div>) :
              <div className='arrow-down-up'></div>}
          </div>
        </div>
        <div className='Metrics__filter--title' onClick={(e) => handleClickTitle(e, 'negative_impact')}>
          Negative Impact
          <div className='Metrics__filter--sort'>
            {getCurrentSortType() === 'negative_impact' ?
              (getCurrentSortDir() === 'desc' ? <div className='arrow-down'></div> : <div className='arrow-up'></div>) :
              <div className='arrow-down-up'></div>}
          </div>
        </div>
      </div>
      <div className='Metrics__filter--content'>
        <div className='Metrics__filter--error'>
          No Results Found
        </div>
      </div>
      <div className='Metrics__filter--footer'>
      </div>
    </div>
  </div>
}

const getCurrentBreakDown = () => {
  const params = new URLSearchParams(history.location.search);
  const videoId = params.get('videoId');
  const streamId = params.get('streamId');
  let breakdown = params.get('breakdown') || 'browser';
  if (videoId) {
    breakdown = 'video_metadata';
  } else if (streamId) {
    breakdown = 'stream';
  }
  return breakdown;
}

const getCurrentSortType = () => {
  const params = new URLSearchParams(history.location.search);
  const type = params.get('order_by') || 'negative_impact';
  return type;
}

const getCurrentSortDir = () => {
  const params = new URLSearchParams(history.location.search);
  const dir = params.get('order_direction') || 'desc';
  return dir;
}

export default Metrics;
