import React, { useState, useRef } from 'react';
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { CopyIcon, DeleteIcon, InfoIcon, RotateIcon, UploadIconIcon } from "../Svg";
import {
    ModalTypes,
    RAGChatbotMaxTemp, RAGChatbotMaxTokens, RAGChatbotMinTemp, RAGChatbotMinTokens,
    RAGChatbotTempStep, RAGChatbotTokenStep
} from "../../constants";
import Ai from '../../store/models/Ai';
import { Button } from '../Button';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import Slider from '../Slider';
import { ChromePicker } from 'react-color';
import { SelectInput } from '../form/SelectInput';
import UIState from '../../store/UIState';
import { GrAdd } from "react-icons/gr";
import Tooltip from '../Tooltip';
import { ChatbotColors } from '../../constants/chatbot-colors';


const getReadableColorName = (cssVarName) => {
    return cssVarName
        .replace('--color-', '')
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const RagSettingsTab = (props) => {
    const { chatbot, projectId, accessToken, setAccessToken } = props;
    const dispatch = useDispatch();
    const endpointOptions = chatbot.llm_endpoints.filter(c => c.id.startsWith('llm_')).map(c => ({ label: c.name, value: c.id }));
    const customEndpoints = chatbot.llm_endpoints.filter(c => c.id.startsWith('c_llm_'))
    const customEndpointOptions = customEndpoints.map(c => ({ label: c.name || c.id, value: c.id, type: 'custom' })).concat({ label: 'Add your own LLM endpoint', value: 'add-custom', type: 'add-custom' });
    let groupedOptions = [{
        label: 'Standard',
        options: endpointOptions
    }, {
        label: 'Custom',
        options: customEndpointOptions
    }]
    // const [endpointOptions, setEndpointOptions] = useState(chatbot.filter(c => c.id.startsWith('llm_')).map(c => ({ label: c.name, value: c.id })));
    const [value, setValue] = useState(() => {
        let id = _.get(chatbot, 'ui.llm_endpoint_id');
        let option = endpointOptions.find(e => e.value === id);
        if (!option) {
            option = customEndpointOptions.find(e => e.value === id);
        }
        return option;
    });

    const [maxTokens, setMaxTokens] = useState(chatbot.max_tokens);
    const [temperature, setTemperature] = useState(chatbot.temperature);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState(_.get(chatbot, 'ui.preset_questions') || []);
    const [warmupQuestions, setWarmupQuestions] = useState(_.get(chatbot, 'warm_up_messages') || []);
    const [showColorSettings, setShowColorSettings] = useState(false);
    const [colors, setColors] = useState(() => {
        const savedColors = _.get(chatbot, 'ui.css_colors', {});
        return ChatbotColors.reduce((acc, { key, default: defaultValue }) => {
            acc[key] = savedColors[key] || defaultValue;
            return acc;
        }, {});
    });

    const retrieverOptions = [
        { value: 'default', label: 'Default' },
        { value: 'llm', label: 'LLM' },
        { value: 'rerank', label: 'Rerank' },
    ];
    const [retriever, setRetriever] = useState(_.find(retrieverOptions, { value: chatbot?.settings?.['retriever.type'] }) ?? retrieverOptions[0]);

    const engineOptions = [
        { value: 'default', label: 'Default' },
        { value: 'agent', label: 'Agent' },
    ];
    const [engine, setEngine] = useState(_.find(engineOptions, { value: chatbot?.settings?.['engine.type'] }) ?? engineOptions[0]);

    const endpintRef = useRef();
    const sysMsgRef = useRef();
    const tokenRef = useRef();
    const defaultDialogRef = useRef();
    const avatarUrlRef = useRef();
    const onEndpointChange = (v) => {
        if (v.value === 'add-custom') {
            dispatch(UIState.actions.showModal(ModalTypes.UPDATE_LLM_ENDPOINT, { type: 'create', projectId, chatbotId: chatbot.id }))
            return;
        }
        setValue(v);
    }

    const onSetMaxTokens = (event) => {
        const value = event.target.value;
        if (_.isEmpty(value)) {
            setMaxTokens(0)
        } else if (value < RAGChatbotMinTokens) {
            setMaxTokens(RAGChatbotMinTokens)
        } else if (value > RAGChatbotMaxTokens) {
            setMaxTokens(RAGChatbotMaxTokens)
        } else {
            setMaxTokens(value)
        }
    }

    const onSetTemperature = (event) => {
        const value = event.target.value;
        if (value < RAGChatbotMinTemp) {
            setTemperature(RAGChatbotMinTemp)
        } else if (value > RAGChatbotMaxTemp) {
            setTemperature(RAGChatbotMaxTemp)
        } else {
            setTemperature(value)
        }
    }

    const handleThemeChange = (newTheme) => {
        setColors(prevColors => {
            return ChatbotColors.reduce((acc, { key, default: defaultValue, light, vgk }) => {
                acc[key] = newTheme === 'light' ? light : (newTheme === 'vgk' ? vgk : defaultValue);
                return acc;
            }, {});
        });
    };

    const handleColorChange = (colorKey, color) => {
        setColors(prevColors => ({
            ...prevColors,
            [colorKey]: color
        }));
    };

    const handleUpdate = async () => {
        if (!endpintRef.current.state.selectValue[0]) {
            endpintRef.current.focus();
            setError("LLM Endpoint can't be empty.");
            return;
        }
        let endpoint = _.get(endpintRef, 'current.state.selectValue[0].value');

        setLoading(true);
        setError(null);
        try {
            let obj = {
                name: chatbot.name,
                project_id: projectId,
                llm_endpoint_id: endpoint,
                max_tokens: maxTokens,
                temperature: temperature,
                system_prompt: sysMsgRef.current.value,
                icon: avatarUrlRef.current.value,
                preset_questions: questions,
                warm_up_messages: warmupQuestions,
                default_dialog: defaultDialogRef.current.value,
                css_colors: colors,
                retriever: retriever.value,
                engine: engine.value,
            };
            await dispatch(Ai.actions.updateRagChatbot(chatbot.id, obj));
            await dispatch(Ai.actions.fetchRagChatbot(projectId, chatbot.id));
            setLoading(false);
            toast.success(`Chatbot ${chatbot.name} has been updated!`)
        } catch (e) {
            console.log('error:', e.message)
            setLoading(false);
            setError(e.message);
        }
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(accessToken)
            .then(() => {
                toast.success('Access Token Copied!')
            })
            .catch((err) => {
                console.error('Failed to copy:', err);
            });
    }

    const handleRotate = () => {
        const onConfirm = async () => {
            try {
                let res = await dispatch(Ai.actions.rotateRagAccessToken(projectId, chatbot.id));
                setAccessToken(res);
                toast.success(`Access token has been updated.`)
            } catch (e) {
                toast.error(`Oops, something went wrong. Error: ${e.message}`)
            }
        }
        dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
            title: `Rotate access token`,
            message: `Do you want to update the access token?`,
            confirmLabel: `Rotate`,
            cancelLabel: `Close`,
            onConfirm
        }))
    }

    return <div className='AiServicePage__tab-content'>
        <div className='RagChatbotDetailPage settings'>
            <div className='RagChatbotDetailPage__row'>
                <div className='RagChatbotDetailPage__input-wrap'>
                    <div className='RagChatbotDetailPage__input-label'>LLM Endpoint</div>
                    <CreatableSelect
                        options={groupedOptions}
                        className={"RagChatbotDetailPage__selector"}
                        placeholder={"LLM Endpoint, e.g. thetalabsorg/ubuntu-sshd:latest"}
                        styles={selectStyles}
                        onChange={onEndpointChange}
                        ref={endpintRef}
                        value={value}
                        isDisabled={!groupedOptions}
                        components={{ Option: (props) => <CustomOption {...props} customEndpoints={customEndpoints} selectedId={_.get(chatbot, 'ui.llm_endpoint_id')} /> }}
                    />
                </div>
            </div>
            <div className='RagChatbotDetailPage__row'>
                <div className='RagChatbotDetailPage__input-wrap'>
                    <div className='RagChatbotDetailPage__input-label'>Access token</div>
                    <div className='RagChatbotDetailPage__input-div token'>
                        <div className='RagChatbotDetailPage__input-div--info'>{accessToken}</div>
                        <div className='RagChatbotDetailPage__input-div--buttons'>
                            <div className='RagChatbotDetailPage__input-div--icon' onClick={handleCopy}>
                                <CopyIcon />
                            </div>
                            <div className='RagChatbotDetailPage__input-div--icon' onClick={handleRotate}>
                                <RotateIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='RagChatbotDetailPage__row large-gap'>
                <div className='RagChatbotDetailPage__input-wrap slider'>
                    <div className='RagChatbotDetailPage__input-label'>Max tokens</div>
                    <Slider className='RagChatbotDetailPage__slider-wrap' step={RAGChatbotTokenStep} id="maxTokensId"
                        max={RAGChatbotMaxTokens} min={RAGChatbotMinTokens} value={maxTokens} onChange={onSetMaxTokens} />
                </div>
                <div className='RagChatbotDetailPage__input-wrap slider'>
                    <div className='RagChatbotDetailPage__input-label'>Temperature</div>
                    <Slider className='RagChatbotDetailPage__slider-wrap' step={RAGChatbotTempStep} id="temperatureId"
                        max={RAGChatbotMaxTemp} min={RAGChatbotMinTemp} value={temperature} onChange={onSetTemperature} />
                </div>

            </div>
            <div className='RagChatbotDetailPage__row large-gap'>
                <div className='RagChatbotDetailPage__input-wrap slider'>
                    <div className='RagChatbotDetailPage__input-label'>Retriever</div>
                    <SelectInput
                        options={retrieverOptions}
                        value={retriever}
                        onChange={(value) => setRetriever(value)}
                        placeholder="Select Retriever"
                        styles={selectStyles}
                        components={{ Option: (props) => <CustomOption {...props} selectedId={chatbot?.settings?.['retriever.type']} /> }}
                    />
                </div>
                <div className='RagChatbotDetailPage__input-wrap slider'>
                    {/* <div className='RagChatbotDetailPage__input-label'>Engine</div>
                    <SelectInput
                        options={engineOptions}
                        value={engine}
                        onChange={(value) => setEngine(value)}
                        placeholder="Select Engine"
                        styles={selectStyles}
                        components={{ Option: (props) => <CustomOption {...props} selectedId={chatbot?.settings?.['engine.type']} /> }}
                    /> */}
                </div>
            </div>

            <div className='RagChatbotDetailPage__row'>
                <div className='RagChatbotDetailPage__input-wrap'>
                    <div className='RagChatbotDetailPage__input-label'>System message
                        <Tooltip tooltip={"Give instructions to the chatbot on how to respond to user messages, give a personality to the chatbot. Not visible to users."}>
                            <InfoIcon />
                        </Tooltip>
                    </div>
                    <textarea ref={sysMsgRef}
                        className='RagChatbotDetailPage__textarea'
                        defaultValue={_.get(chatbot, 'system_prompt')} />
                </div>
            </div>
            <div className='RagChatbotDetailPage__row'>
                <div className='RagChatbotDetailPage__input-wrap'>
                    <div className='RagChatbotDetailPage__input-label'>Default dialog
                        <Tooltip tooltip={"Welcome message displayed when the chatbot is opened."}>
                            <InfoIcon />
                        </Tooltip>
                    </div>
                    <input ref={defaultDialogRef}
                        className='RagChatbotDetailPage__input'
                        defaultValue={_.get(chatbot, 'ui.default_dialog')} />
                </div>
            </div>

            <div className='RagChatbotDetailPage__row'>
                <div className='RagChatbotDetailPage__input-wrap'>
                    <div className='RagChatbotDetailPage__input-label'>Chatbot avatar URL
                        <Tooltip tooltip={"Avatar image displayed in front of the chatbot."}>
                            <InfoIcon />
                        </Tooltip>
                    </div>
                    <input ref={avatarUrlRef}
                        className='RagChatbotDetailPage__input'
                        defaultValue={_.get(chatbot, 'ui.icon')} />
                </div>
            </div>

            <div className='RagChatbotDetailPage__row large-gap'>
                <div className='RagChatbotDetailPage__input-wrap'>
                    <div className='RagChatbotDetailPage__input-label'>Preset questions
                        <Tooltip tooltip={"Questions will be displayed at the bottom of the chatbot. Answers are optional, if not provided, the chatbot will generate an answer."}>
                            <InfoIcon />
                        </Tooltip>
                    </div>
                    <div className='RagChatbotDetailPage__preset-questions' >
                        <div className='RagChatbotDetailPage__preset-questions--wrap'>
                            {/* <input type='text' className='RagChatbotDetailPage__input' /> */}
                            <QuestionsSection questions={questions} setQuestions={setQuestions} />
                            <div className='RagChatbotDetailPage__preset-questions--icon' onClick={() => setQuestions(q => q.concat({ question: '', answer: '' }))}>
                                <UploadIconIcon /> New
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='RagChatbotDetailPage__row large-gap'>
                <div className='RagChatbotDetailPage__input-wrap'>
                    <div className='RagChatbotDetailPage__input-label'>Warmup messages
                        <Tooltip tooltip={"Messages that will be used to warm up the chatbot. They will be added to the chatbot knowledge and won't be displayed to users."}>
                            <InfoIcon />
                        </Tooltip>
                    </div>
                    <div className='RagChatbotDetailPage__preset-questions' >
                        <div className='RagChatbotDetailPage__preset-questions--wrap'>
                            <WarmupMessagesSection questions={warmupQuestions} setQuestions={setWarmupQuestions} />
                            <div className='RagChatbotDetailPage__preset-questions--icon' onClick={() => setWarmupQuestions(q => q.concat({ role: 'user', content: '' }))}>
                                <UploadIconIcon /> New
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='RagChatbotDetailPage__row'>
                <div className='RagChatbotDetailPage__input-wrap'>
                    <div
                        className='RagChatbotDetailPage__input-label collapsible'
                        onClick={() => setShowColorSettings(!showColorSettings)}
                    >
                        Chatbot Colors {showColorSettings ? '▼' : '►'}
                    </div>
                    {showColorSettings && (
                        <div className='RagChatbotDetailPage__color-settings-container'>
                            <div className='RagChatbotDetailPage__color-picker'>
                                <label>Apply color theme</label>
                                <select
                                    onChange={(e) => handleThemeChange(e.target.value)}
                                    className='RagChatbotDetailPage__color-theme-selector'
                                    defaultValue=""
                                >
                                    <option value="" disabled>Choose a theme to apply</option>
                                    <option value="default">Dark</option>
                                    <option value="light">Light</option>
                                    {/* <option value="vgk">VGK</option> */}
                                </select>
                            </div>

                            <div className='RagChatbotDetailPage__color-settings'>
                                {ChatbotColors.map(({ key }) => (
                                    <div key={key} className='RagChatbotDetailPage__color-picker'>
                                        <label>{getReadableColorName(key)}</label>
                                        <ColorPicker
                                            color={colors[key]}
                                            setColor={(color) => handleColorChange(key, color)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>)}
                </div>
            </div>

            <div className='RagChatbotDetailPage__row center'>
                <Button color="green" title={"Update"} onClick={handleUpdate} size={'large'} loading={loading} disabled={loading} />
            </div>
        </div>
    </div >
}

const CustomOption = ({ data, customEndpoints, selectedId, ...props }) => {
    const dispatch = useDispatch();
    const { projectId, id } = useParams();
    const onEdit = (e, endpoints) => {
        e.stopPropagation();
        const endpoint = _.get(endpoints, 0);

        dispatch(UIState.actions.showModal(ModalTypes.UPDATE_LLM_ENDPOINT, { type: 'edit', endpoint, projectId, chatbotId: id }))
        return;
    }
    const onDelete = (e, endpoints) => {
        e.stopPropagation();
        const endpoint = _.get(endpoints, 0);
        const onConfirm = async () => {
            await dispatch(Ai.actions.deleteLlmEndpoint(projectId, id, endpoint.id));
            await dispatch(Ai.actions.fetchRagChatbot(projectId, id));
        }
        dispatch(UIState.actions.showModal(ModalTypes.DELETE, { title: endpoint.name || endpoint.id, onConfirm }));
    }
    return (
        <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: data.type === 'custom' ? 'space-between' : 'flex-start' }}>
                {data.value === 'add-custom' && <GrAdd style={{ marginRight: 5 }} />}
                <span>{data.label}</span>
                {data.type === 'custom' && <div>
                    <Button color='green-outline' title="Edit" size='small'
                        onClick={(e) => onEdit(e, customEndpoints.filter(e => e.id === data.value))} />
                    <Button color='red-outline' title="Remove" size='small' style={{ marginLeft: 5 }}
                        disabled={data.value === selectedId}
                        onClick={(e) => onDelete(e, customEndpoints.filter(e => e.id === data.value))} />
                </div>}
            </div>
        </components.Option>
    );
}


const QuestionsSection = ({ questions, setQuestions }) => {

    const handleQuestionChange = (index, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].question = value;
        setQuestions(updatedQuestions);
    };

    const handleAnswerChange = (index, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].answer = value;
        setQuestions(updatedQuestions);
    };

    return <div className='RagChatbotDetailPage__questions'>
        {questions.map((q, i) => {
            return <div className='RagChatbotDetailPage__question-container' key={i}>
                <div className='RagChatbotDetailPage__question'>
                    <div className='RagChatbotDetailPage__question--wrap'>
                        <div className='RagChatbotDetailPage__question--label'>Question #{i + 1}</div>
                        <input className='RagChatbotDetailPage__question--input'
                            type="text" placeholder={`Enter question #${i + 1}`}
                            value={q.question}
                            onChange={(e) => handleQuestionChange(i, e.target.value)} />
                    </div>
                    <div className='RagChatbotDetailPage__question--wrap'>
                        <div className='RagChatbotDetailPage__question--label'>Answer #{i + 1}</div>
                        <input className='RagChatbotDetailPage__question--input'
                            type="text" placeholder={`Enter answer #${i + 1}, or leave blank if you want the chatbot to generate the answer dynamically`}
                            value={q.answer}
                            onChange={(e) => handleAnswerChange(i, e.target.value)} />
                    </div>
                </div>
                <div className='RagChatbotDetailPage__question-icon-container'>
                    <div className='RagChatbotDetailPage__question-delete-icon'
                        onClick={() => setQuestions(pre => pre.filter((_, idx) => idx !== i))}>
                        <DeleteIcon />
                    </div>
                </div>
            </div>
        })}
    </div >
}


const WarmupMessagesSection = ({ questions, setQuestions }) => {
    const roleOptions = [
        { value: 'user', label: 'User' },
        { value: 'assistant', label: 'Assistant' },
    ];

    const handleMessageChange = (index, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].content = value;
        setQuestions(updatedQuestions);
    };

    const handleRoleChange = (index, selectedOption) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].role = selectedOption.value;
        setQuestions(updatedQuestions);
    };

    return <div className='RagChatbotDetailPage__questions'>
        {questions.map((q, i) => {
            return <div className='RagChatbotDetailPage__question-container' key={i}>
                <div className='RagChatbotDetailPage__question'>
                    <div className='RagChatbotDetailPage__question--wrap warmup'>
                        <input className='RagChatbotDetailPage__question--input'
                            type="text" placeholder={`Enter message #${i + 1}`}
                            value={q.content}
                            onChange={(e) => handleMessageChange(i, e.target.value)} />
                        <SelectInput
                            options={roleOptions}
                            value={roleOptions.find(option => option.value === q.role)}
                            onChange={(value) => handleRoleChange(i, value)}
                            styles={selectStyles}
                            components={{ Option: (props) => <CustomOption {...props} selectedId={q.role} /> }}
                        />
                    </div>
                </div>
                <div className='RagChatbotDetailPage__question-icon-container'>
                    <div className='RagChatbotDetailPage__question-delete-icon'
                        onClick={() => setQuestions(pre => pre.filter((_, idx) => idx !== i))}>
                        <DeleteIcon />
                    </div>
                </div>
            </div>
        })}
    </div>
}

const ColorPicker = (props) => {
    const { color, setColor } = props;
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleClick = () => {
        setDisplayColorPicker(v => !v)
    }

    const handleClose = () => {
        setDisplayColorPicker(false);
    }

    const handleChange = (color) => {
        setColor(color.hex)
    }

    return <div className='RagChatbotDetailPage__color'>
        <div className='RagChatbotDetailPage__color--swatch' onClick={handleClick}>
            <div className='RagChatbotDetailPage__color--color' style={{ background: color }} />
            <div className='RagChatbotDetailPage__color--hexcode'>{color}</div>
        </div>
        {displayColorPicker && <div className='RagChatbotDetailPage__color--popover'>
            <div className='RagChatbotDetailPage__color--cover' onClick={handleClose} />
            <ChromePicker color={color} onChange={handleChange} />
        </div>}
    </div>
}


const selectStyles = {
    container: (styles, { isDisabled }) => ({
        ...styles,
        flex: 1,
        height: 40,
        cursor: isDisabled ? 'not-allowed' : 'default',
    }),
    control: (styles, { isDisabled }) => ({
        ...styles,
        paddingLeft: 15,
        backgroundColor: '#191D29',
        borderColor: '#3D4463',
        borderRadius: 6,
        ':hover': {
            borderColor: 'white',
        },
        '.selected': {
            borderColor: 'white',
        },
    }),
    menu: (styles, state) => ({
        ...styles,
        border: '1px solid #3D4463',
        backgroundColor: '#191D29',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: '#191D29',
            borderColor: '#3D4463',
            color: isDisabled ? '#636B91' : (isFocused ? 'white' : (isSelected ? 'white' : '#636B91')),
            opacity: isDisabled ? 0.5 : 1,
        };
    },
    dropdownIndicator: (styles, state) => ({
        ...styles,
        color: '#8A8FB5',
    }),
    indicatorsContainer: (styles, { isDisabled }) => ({
        ...styles,
        display: isDisabled ? 'none' : 'flex',
    }),
    indicatorSeparator: (styles, state) => ({
        ...styles,
        color: '#636B91',
        backgroundColor: '#636B91',
        display: 'none'
    }),
    input: styles => ({ ...styles, color: "#fff", height: 30 }),
    placeholder: styles => ({ ...styles, color: '#636B91' }),
    singleValue: (styles, { data }) => ({ ...styles, color: '#fff', fontWeight: '500' }),
};

