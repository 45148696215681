import { Button } from "./Button";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import Dapp, { selectAllDapps, selectDapp, selectDappVideoAPIAccount, selectServiceAccount } from "../store/models/DApp";
import Bill from "../store/models/Bill";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import User, { selectCurrentUser, selectCurrentUserId } from "../store/models/User";
import { pushTo } from "../store/history";
import { Link, NavLink } from "react-router-dom";
import { createSelector } from "reselect";
import { ModalTypes, Urls, UserRoleIds as UserRoles } from "../constants";
import Project, { selectCurrentProject, selectCurrentProjectId } from "../store/models/Project";
import {DownChevronIcon, MenuIcon, VerticalDotsIcon} from "./Svg";
import UIState from "../store/UIState";
import { selectCurrentOrgId } from "../store/models/Organization";
import cx from "classnames";
import { DropdownMenu, DropdownMenuItem } from "./DropdownMenu";

export const HeaderLink = ({ to, target, onClick, name, active, className }) => {
  return (
    <NavLink className={cx('NavbarLink', { 'NavbarLink--active': active }, className)}
      activeClassName={'NavbarLink--active'}
      to={to}
      onClick={onClick}
      target={target}
      rel="noopener noreferrer"
      exact
    >
      {name}
      {active && <div className={'NavbarLink__border'} />}
    </NavLink>
  );
}

const selector = createSelector(
  selectCurrentUser,
  selectCurrentUserId,
  selectCurrentOrgId,
  selectCurrentProjectId,
  selectCurrentProject,
  (user, userId, currentOrgId, currentProjectId, currentProject) => {
    return {
      user,
      userId,
      currentOrgId,
      currentProjectId,
      currentProject,
    }
  }
);

export const MobileNavbar = ({ }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, userId, currentOrgId, currentProjectId, currentProject }
    = useSelector(state => selector(state));

  const pathName = location.pathname;
  const inDashboard = pathName.includes('/dashboard');
  const isShowcase = pathName === Urls.MARKETING_AI_SHOWCASES
    || pathName === Urls.MARKETING_AI_SHOWCASE_TEXT_TO_IMAGE
    || pathName === Urls.MARKETING_AI_SHOWCASE_CHATBOT
    || pathName === Urls.MARKETING_AI_SHOWCASE_IMAGE_TO_VIDEO;


  useEffect(() => {
    if (!_.isNil(currentProjectId) && _.isEmpty(currentProject)) {
      dispatch(Project.actions.fetchProject(currentProjectId));
    }
  }, [currentProjectId]);

  const onSelectProject = () => {
    dispatch(UIState.actions.showModal(ModalTypes.SELECT_PROJECT));
  }

  const onLogout = () => {
    dispatch(UIState.actions.showModal(ModalTypes.LOGOUT, {
      onConfirm: onConfirmLogout
    }))
  }

  const onConfirmLogout = () => {
    dispatch(User.actions.logout())
  }


  const onOpenSettings = () => {
    pushTo(Urls.SETTINGS_PROFILE)
  }

  const onToggleSideMenu = () => {
    dispatch(UIState.actions.toggleSideMenu())
  }

  return (
    <div className={cx(`Navbar`)}>

      <div className={cx(`Navbar__content`)}>
        {/*<div className="Navbar__side-menu" onClick={onToggleSideMenu}>*/}
        {/*  <MenuIcon color="sub" className="hamburger"/>*/}
        {/*</div>*/}

        <Link to={userId ? Urls.DASHBOARD : Urls.HOME}>
          <img src={"/images/edgecloud-logo-mobile.svg"}
               alt={'theta-video-api-logo'}
               className={'Navbar__logo Navbar__logo--mobile'}
          />
        </Link>

        {/* {currentOrgId && !_.isNil(userId) && inDashboard &&
          <Button className={"SelectProjectButton"}
                  title={currentProject?.name ?? "Select project"}
                  color={"transparent"}
                  onClick={onSelectProject}
                  icon={<DownChevronIcon/>}
          />} */}

        <div className={'Navbar__user-content'}>

          <HeaderLink to={Urls.DASHBOARD}
                      name={'Dashboard'}
                      active={pathName.includes(Urls.DASHBOARD)} />

          <HeaderLink to={Urls.MARKETING_AI_SHOWCASES}
                      name={'AI Showcase'}
                      active={isShowcase} />

        </div>
      </div>
    </div>
  )
}